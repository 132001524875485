import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  TableContainer,
  Paper,
} from "@mui/material";
import planIcon from "../../assets/icons/planIcon.svg";
import Card from "@mui/material/Card";
import { getPlan, updatePlan } from "../../service/plan.api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "./style.css";
// import crown from "../../assets/images/crown.png";
import crown from "../../assets/icons/crown.svg";
import PaymentList from "./paymentList";

const PlanManagement = () => {
  const [plan, setPlan] = useState({ amount: "", discount: "" });
  const [isRefreshAmount, setIsRefreshAmount] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  useEffect(() => {
    async function fetch() {
      setOpenBackdrop(true);
      try {
        const res = await getPlan();
        setPlan({
          amount: res.data.data.amount,
          discount: res.data.data.discount,
        });
      } catch (error) {
        console.log("ERROR: Plan list", error);
      } finally {
        setOpenBackdrop(false);
      }
    }
    fetch();
  }, [isRefreshAmount]);

  const validationSchema = Yup.object().shape({
    data: Yup.number().required("Amount is required"),
    discountData: Yup.string()
      .required("Discount % is required")
      .typeError("Discount must be a number"),
      // .test(
      //   "is-valid-discount",
      //   // "Discount should be a number followed by % sign",
      //   (value) => {
      //     if (!value) return true; // Allow empty value
      //     const regex = /^\d+%$/; // Regular expression to match number followed by %
      //     return regex.test(value); // Check if value matches the regex pattern
      //   }
      // ),
  });

  const formik = useFormik({
    initialValues: { data: "", discountData: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {

      setOpenBackdrop(true);
      try {
        const res = await updatePlan(values);
        toast.success(res.data.message.html);
        setIsRefreshAmount(!isRefreshAmount);
        resetForm();
      } catch (error: any) {        
       if(error.response.data.message.html !== '' && error.response.data.message.html !== undefined){
        toast.error(error.response.data.message.html);
       }else{        
        toast.error(error.response.data.message);
       }
      } finally {
        setOpenBackdrop(false);
      }
    },
  });

  return (
    <Box p={3}>
      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <CircularProgress sx={{ color: "white" }} />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item lg={6} md={8} xs={12}>
          <Card
            sx={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box p={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar className="avatar-background">
                  <img src={planIcon} alt="Plan Icon" />
                </Avatar>
                <Typography
                  sx={{
                    fontFamily: "Onest-Bold",
                    fontSize: "36px",
                    mt: 0,
                    ml: 1,
                  }}
                >
                  Membership Info
                </Typography>
              </div>
              <Grid sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    fontFamily: "Onest-Medium",
                    fontSize: "20px",
                    color: "#475467",
                  }}
                >
                  Premium Price per month: &nbsp;
                  <span
                    style={{
                      fontFamily: "Onest-Bold",
                      fontWeight: 500,
                      fontSize: "20px",
                    }}
                  >
                    {plan?.amount &&
                      plan?.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Onest-Medium",
                    fontSize: "20px",
                    color: "#475467",
                  }}
                >
                  Annually discount: &nbsp;
                  <span
                    style={{
                      fontFamily: "Onest-Bold",
                      fontWeight: 500,
                      fontSize: "20px",
                    }}
                  >
                    {plan?.discount &&
                      plan?.discount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </Typography>
              </Grid>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundImage: `url(${crown})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                opacity: 0.05,
                zIndex: 0,
              }}
            ></Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={8} xs={12}>
          <Card>
            <Box p={3}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography
                      className="lable-name"
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "6px",
                        alignSelf: "stretch",
                        marginBottom: "10px",
                      }}
                    >
                      Premium Price per month
                    </Typography>
                    <TextField
                      className="formFields"
                      sx={{
                        mt: 0,
                        borderRadius: "8px",
                        width: "100% !important",
                        "& input::placeholder": {
                          color: "black !imporatnt",
                        },
                      }}
                      name="data"
                      placeholder="Change Amount"
                      value={formik.values.data}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.data && Boolean(formik.errors.data)}
                      helperText={formik.touched.data && formik.errors.data}
                      inputProps={{
                        maxLength: 6,
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Typography
                      className="lable-name"
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "6px",
                        alignSelf: "stretch",
                        marginBottom: "10px",
                      }}
                    >
                      Annually discount in %
                    </Typography>
                    <TextField
                      className="formFields"
                      sx={{
                        mt: 0,
                        borderRadius: "8px",
                        width: "100% !important",
                        "& input::placeholder": {
                          color: "black !imporatnt",
                        },
                      }}
                      name="discountData"
                      placeholder="Change Discount Percent"
                      value={`${formik.values.discountData}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.discountData &&
                        Boolean(formik.errors.discountData)
                      }
                      helperText={
                        formik.touched.discountData &&
                        formik.errors.discountData
                      }
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  className="common-button-green"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Save
                </Button>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Grid sx={{ mt: "15px" }}>
        <TableContainer component={Paper}>
          <PaymentList />
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default PlanManagement;
