import { Route, Routes } from "react-router-dom";
import Dashboard from "../features/dashboard";
import ProtectedRouteLayout from "../components/layout/protectedRouteLayout";
import PlanManagement from "../features/planManagement/planManagement";
import User from "../features/user/user";
import UserProfile from "../features/userProfile/userProfile";
import Skill from "../features/skill/skill";
import Tool from "../features/tool/tool";
import Logout from "../features/logout/logout";
import Discount from "../features/discountCode/discount";
import SignIn from "../features/signIn/signIn";
import ForgotPassword from "../features/forgotPassword/forgotPassword";
import SignInLayout from "../components/layout/signInLayout";
import ResetPassword from "../features/resetPassword";
import PageNotFound from "../features/pageNotFound/pageNotFound";

const ProtectedRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRouteLayout />}>
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="/dashboard" element={<ProtectedRouteLayout />}>
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="/plan/*" element={<ProtectedRouteLayout />}>
        <Route index element={<PlanManagement />} />
      </Route>
      <Route path="/user" element={<ProtectedRouteLayout />}>
        <Route index element={<User />} />
      </Route>
      <Route path="/discount" element={<ProtectedRouteLayout />}>
        <Route index element={<Discount />} />
      </Route>
      <Route path="/user-profile/:id" element={<UserProfile />} />
      <Route path="/skill" element={<ProtectedRouteLayout />}>
        <Route index element={<Skill />} />
      </Route>
      <Route path="/tool" element={<ProtectedRouteLayout />}>
        <Route index element={<Tool />} />
      </Route>
      <Route path="/logout" element={<ProtectedRouteLayout />}>
        <Route index element={<Logout />} />
      </Route>
      <Route path="/signin" element={<SignInLayout />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path="/forgot-password" element={<SignInLayout />}>
        <Route index element={<ForgotPassword />} />
      </Route>
      <Route path="/reset-password" element={<SignInLayout />}>
        <Route index element={<ResetPassword />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default ProtectedRoute;
