import React from "react";
import { Box, Button } from "@mui/material";
import notFoundImage from "../../assets/images/NotFound.png";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <Box sx={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
        <img
          src={notFoundImage}
          alt="pagenotfound"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        />
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            zIndex: "1",
            top: "68%",
            left: "50%",
            fontFamily: "'Onest', sans-serif",
            transform: "translate(-50%,-50%)",
            backgroundColor: "white",
            color: "var(--Primary, #2CB512)",
            fontSize: "18px",
            fontWeight: "550",
            border: "1px solid white",
            textTransform: "none",
            padding: "10px 85px",
            borderRadius: "16px",
            "&:hover": {
              backgroundColor: "white",
              border: "1px solid white",
            },
          }}
          onClick={handleRedirect}
        >
          Back To Home
        </Button>
      </Box>
    </>
  );
};

export default PageNotFound;
