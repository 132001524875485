import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import favicon from "../../assets/icons/favicon.svg";
import backIcon from "../../assets/icons/backIcon.svg";
import { useNavigate ,Link } from "react-router-dom";
import { forgotPassword } from "../../service/signin.api";
import { toast } from "react-toastify";

const siteUrl = process.env.REACT_APP_MARKETING_URL;

const ForgotPassword = () => {
  
  const navigate = useNavigate();
  const initialValue = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: async (values) => {
      await forgotPassword(values)
        .then((res) => {
          toast.success(res.data.message.html);
          formik.resetForm();
          navigate("/signin");
        })
        .catch((error) => {
          toast.error(error.response.data.message.html);
        });
    },
  });

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <Box sx={{ padding: "45px 30px" }}>
        <Stack
          direction={"row"}
          mb={4}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <Avatar sx={{ bgcolor: "#2CB512", width: 24, height: 24, mr: 2 }}>
            <img src={backIcon} alt="backIcon" />
          </Avatar>
          <Typography className="font-semibold">Back</Typography>
        </Stack>
        {siteUrl ? (
        <Link to={siteUrl} target="blank">
          <img src={favicon} alt="favicon" />
        </Link>
      ) : ('')}
        
        
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "'Onest', sans-serif",
              fontWeight: "900",
              fontSize: "32px",
            }}
          >
            Forgot Password ?
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              color: "var(--gray-500, #667085)",
            }}
            mb={4}
          >
            Enter your registered email to verify OTP.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "6px",
                alignSelf: "stretch",
                marginBottom: "10px",
              }}
            >
              Enter Email
            </Typography>
            <TextField
              className="formFields"
              sx={{ width: "450px" }}
              name="email"
              placeholder="Enter Email Here"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && formik.touched.email && (
              <Typography className="formError">
                {formik.errors.email}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
