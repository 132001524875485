import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
  icon: string;
  count: number;
  title: string;
};
const UserCount = ({ icon, count, title }: Props) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{backgroundColor:'white',border:' 1px solid #D0D5DD',borderRadius:'30px'}}>
            <img src={icon} alt="user logo" />
          </Avatar>
        }
        title={
          <Typography className="font-bold" sx={{ fontSize: "36px" }}>
            {count}
          </Typography>
        }
      />
      <CardContent>
        <Typography className="font-medium"  sx={{fontSize: "18px", color: "#475467",textTransform: "capitalize",}}>{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default UserCount;
