import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleCategory: (values:{category: string}) => void;
  createCategory: {
    category: string,
    isEdit: boolean
  }
};

const CategoryDialog = ({
  open,
  handleClose,
  handleCategory,
  createCategory
}: Props) => {
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Category name is required"),
  });

  const formik = useFormik({
    initialValues: {category:createCategory.category},
    validationSchema,
    onSubmit: async (values) => {
      handleCategory(values)
    },
  });
  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ padding: "18px 24px 5px 24px" }}
      >
        <Typography
          sx={{
            fontFamily: "Onest-Semibold",
            fontWeight: 600,
            fontSize: "18px",
            color: "#2CB512",
          }}
        >
          {createCategory.isEdit?"Edit":"Add"} Main Skill
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Typography
          className="lable-name"
          mt={2}
          sx={{
            marginBottom: "10px",
          }}
        >
          Write the main Skill <span style={{ color: 'red',marginLeft:'2px' }}>*</span>
        </Typography>

        <TextField
          fullWidth
          className="formFields"
          placeholder="Main Skill"
          sx={{ mt: 0, borderRadius: "8px" }}
          onChange={formik.handleChange}
          value={formik.values.category}
          name="category"
        />
        {formik.errors.category && formik.touched.category && (
            <Typography className="formError">
              {formik.errors.category}
            </Typography>
          )}
      </DialogContent>

      <DialogActions id="alert-dialog-description" sx={{ px: 7, pb: 3 }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            fontFamily: "Onest-Semibold",
            textTransform: "none",
            width: "100%",
            fontSize: "14px",
            borderColor: "#2CB512",
            color: "#2CB512",
            borderRadius: "12px",
            "&:hover": {
              color: "#2CB512",
              borderColor: "#2CB512",
              backgroundColor: "white",
            },
          }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          type="submit"
          sx={{
            fontFamily: "Onest-Semibold",
            textTransform: "none",
            width: "100%",
            backgroundColor: "#2CB512",
            fontSize: "14px",
            borderRadius: "12px",
            "&:hover": { backgroundColor: "#2CB512" },
          }}
        >
          {createCategory.isEdit?"Update":"Add"} Main Skill
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};

export default CategoryDialog;
