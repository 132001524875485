// DeleteUserDialog.tsx

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";

interface DeleteUserDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteDialog: React.FC<DeleteUserDialogProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  return (
    <Dialog open={open} >
      <DialogContent sx={{p:5}}>
      <DialogContentText sx={{color:'#404143', marginTop:'10px'}}>
        Are you sure you want to Delete ?
      </DialogContentText> 
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", marginBottom:'20px' }}>
        <Button onClick={onClose} variant="contained"
        sx={{
            textTransform:'none',
            color:"#6E7472",
            backgroundColor:'#FFF',
            border: '1px solid #6E7472',
            borderRadius:'20px',
            width:'110px',
            "&:hover": {
                backgroundColor: "#FFF",
              },
        }}>
          Cancel
        </Button>
        <Button onClick={onDelete}  variant="contained" 
        sx={{
            textTransform:'none',
            color:"#FFF",
            backgroundColor:'#475467',
            borderRadius:'20px',
            width:'110px',
            "&:hover": {
                backgroundColor: "#475467",
              },
        }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;