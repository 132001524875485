import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import userLogo from "../../assets/icons/userLogo.svg";

const AdminProfile = () => {
  return (
    <Box sx={{ position: "absolute", bottom: 10, width: "100%" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            background: "#F2F4F7",
            padding: "5%",
            borderRadius: "30px",
            cursor: "pointer",
            width: "70%",          
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <img src={userLogo} alt="user logo" />
            <Typography
              className="font-semibold"
              sx={{ ml: 1, color: "#344054" }}
            >
              Admin
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminProfile;
