import React, { useEffect } from "react";
import { logout } from "../../service/logout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; 

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          const response = await logout({ token: refreshToken });
          toast.success(response.data.message.html)
        }
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
        navigate("/signin");
      } catch (error:any) {
        console.error("Logout failed:", error);
      }
    };

    performLogout();
  }, [navigate]);

  return <div>Logout</div>;
};

export default Logout;
