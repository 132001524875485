import { AxiosError } from "axios";
import api from "./api";

export const logout = (data: { token: string | null }) => {
  return api
    .post("/login/logout", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
