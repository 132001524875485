/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  TablePagination,
  useTheme,
  TextField,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import eyeIcon from "../../assets/icons/eyeIcon.svg";
import mailIcon from "../../assets/icons/MailIcon.svg";
import { getPaymentUser } from "../../service/User/user.api";
import { IPaymentDetails } from "../../interface/apiInterface/IUser";
import SearchIcon from "@mui/icons-material/Search";

const PaymentList = () => {
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    recordPerPage: 5,
    availableRecords: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isRefreashPage, setIsRefreshPage] = useState(false);
  const [search, setSearch] = useState("");
  // const [searchParam, setSearchParam] = useState("");
  const [userData, setUserData] = useState<IPaymentDetails | null>(null);

  const theme = useTheme();

  useEffect(() => {
    let param: { search?: string; limit: number; page: number } = {
      limit: pagination.recordPerPage,
      page: pagination.currentPage,
    };
    if (search !== "") {
      param.search = search;
    }
    getPaymentUser(param)
      .then((response) => {
        const data = response.data;

        setPagination({
          ...pagination,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          recordPerPage: data.paymentsPerPage,
          availableRecords: data.totalAvailablePayments,
        });
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isRefreashPage, search]);

  const handleComposeMailClick = (email: string, pdfLink: string) => {
    const encodedPdfLink = encodeURIComponent(pdfLink);
    const bodyText = `Hi,\n\nPlease find the link:\n${encodedPdfLink}`;
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&body=${bodyText}`;
    window.open(gmailLink, "_blank");
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPagination({ ...pagination, currentPage: newPage + 1 });
    setIsRefreshPage(!isRefreashPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination({ ...pagination, recordPerPage: Number(event.target.value) });
    setIsRefreshPage(!isRefreashPage);
  };

  return (
    <div style={{ padding: "20px" }}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item lg={2}>
          <Typography
            sx={{
              color: "#667085",
              fontFamily: "'Onest', sans-serif",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            All Payments
          </Typography>
        </Grid>
        <Grid item lg={5}>
          <TextField
            id="search"
            placeholder="Search username or email"
            className="formFields placeholder-black"
            variant="outlined"
            sx={{
              width: "100%",
              "& input::placeholder": {
                color: "black !important", // Specify the color for the placeholder text
              },
            }}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
              // Fetch data when search field is changed
              if (event.target.value.trim() === "") {
                // setSearchParam(""); // Reset the search parameter to fetch all data
                setSearch("");
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // setSearchParam(search);
                setSearch(search);
              }
            }}
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    marginRight: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => setSearch(search)}>
                    <SearchIcon sx={{ color: "#646972" }} />
                  </IconButton>
                </Box>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell className="font-semibold" align="left">
              No
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Username
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Transactopn Id
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Email
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Gateway
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Amount
            </TableCell>
            <TableCell className="font-semibold" align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData &&
            userData?.results?.map((user: any, index: number) => {
              const rowNumber = index + 1;
              return (
                <TableRow
                  key={user.email}
                  sx={{
                    "& td, & th": {
                      borderBottom: "1px solid #F2F2F2",
                    },
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row" align="left">
                    {rowNumber}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {user.userName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {user.transactionId}
                  </TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.gateway}</TableCell>
                  <TableCell align="left">{user.amount}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{ display: "flex", gap: 1, justifyContent: "center" }}
                    >
                      <Fab
                        sx={{
                          backgroundColor: "white",
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                        }}
                        size="small"
                        aria-label="add"
                        // onClick={() => handleUserProfileClick(user.id)}
                        // onClick={() => handleEyeIconClick(user.pdfLink)}
                        onClick={() => window.open(user.pdfLink, "_blank")}
                      >
                        <img src={eyeIcon} alt="Eye Icon" />
                      </Fab>

                      <Fab
                        sx={{
                          backgroundColor: "white",
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                        }}
                        size="small"
                        aria-label="add"
                        onClick={() =>
                          handleComposeMailClick(user.email, user.pdfLink)
                        }
                      >
                        <img src={mailIcon} alt="Mail Icon" />
                      </Fab>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={pagination.availableRecords}
        page={pagination.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.recordPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<span style={{ fontWeight: "bold" }}>Page:</span>}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{
          marginTop: theme.spacing(2),
          "& .MuiTablePagination-caption": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-select": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-toolbar": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-displayedRows": {
            fontWeight: "bold",
          },
        }}
      />
    </div>
  );
};

export default PaymentList;
