import { Box, Stack, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Navigate, Outlet, useNavigate} from "react-router-dom";
import SideBar from "../../features/sidebar/sidebar";
import AdminProfile from "../../features/sidebar/adminProfile";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
// import sideBarIcon from "../../assets/icons/sideBarIcon.svg";
import sideNavLeftIcon from "../../assets/images/sideNavLeftIcon.png";
import sideNavRightIcon from "../../assets/images/sideNavRightIcon.png";


const ProtectedRouteLayout = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [isSidebarVisible, setSidebarVisibility] = useState(true);

  useEffect(() => {
    if (!token) {
      localStorage.removeItem("token"); // Clear any potential invalid token
    }
  }, [token]);

  const toggleSidebar = () => {
    setSidebarVisibility((prevVisibility) => !prevVisibility);

    const sidebar = document.querySelector(".side-bar");
    sidebar?.classList.toggle("show-sliderbar");

    const rightbar = document.querySelector(".right-bar");
    rightbar?.classList.toggle("show-bar");
  };

  return (
    <Box sx={{ background: "#F7F8F8" }}>
      {token ? (
        <Stack direction={"row"}>
          <Box
            className="side-bar"
            sx={{
              width: "370px",
              background: "white",
              minHeight: "100vh",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                width: "265px",
                background: "white",
                minHeight: "100vh",
              }}
            >
              <Grid
                className="close-icon"
                onClick={toggleSidebar}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 8,
                  backgroundColor: "#2CB512",
                  color: "#fff",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                  marginTop: "8px",
                }}
              >
                <CloseIcon />
              </Grid>
              <Box sx={{ ml: 5, mt: 6 }}>

                <img onClick = {() => navigate("/")} src={logo} alt="logo"  style={{cursor:"pointer"}}/>
              </Box>
              <SideBar />
              <AdminProfile />
            </Box>
          </Box>
          <Box
            className="right-bar"
            sx={{
              width: "100%",
              minHeight: "100vh",
              // ml: isSidebarVisible ? "370px" : "0px",
            }}
          >
            <Grid
              className="admin-btn"
              onClick={toggleSidebar}
              sx={{
                backgroundColor: "#2CB512",
                color: "#fff",
                borderRadius: "90%",
                height: "30px",
                width: "30px",
                marginLeft: "22px",
                marginTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isSidebarVisible ? (
                <img src={sideNavLeftIcon} alt="sideLeft" />
              ) : (
                <img src={sideNavRightIcon} alt="sideRight" />
              )}
            </Grid>
            <Outlet />
          </Box>
        </Stack>
      ) : (
        <Navigate to="/signin" replace />
      )}
    </Box>
  );
};

export default ProtectedRouteLayout;
