/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Fab,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  TablePagination,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import eyeIcon from "../../assets/icons/eyeIcon.svg";
import mailIcon from "../../assets/icons/MailIcon.svg";
import trashIcon from "../../assets/icons/trashIcon.svg";
import DeleteDialog from "../../components/deletePopUp";
import { getUser, deleteUser, statusUpdate } from "../../service/User/user.api";
import { IUser } from "../../interface/apiInterface/IUser";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ViewUserDialog from "./viewUserDialog";
import EditUserDialog from "./editUserDialog";
import { getUserProfile, updateUserProfile } from "../../service/User/user.api";
import { IUserDetail } from "../../interface/apiInterface/IUser";

type Props = {
  search: string;
};

const ActiveUserTable = ({ search }: Props) => {
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    recordPerPage: 5,
    availableRecords: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectDropDown, setSelectDropDown] = useState({
    userName: "",
    status: "",
  });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userData, setUserData] = useState<IUser | null>(null);
  const [deleteUserId, setDeleteUserId] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRefreashPage, setIsRefreshPage] = useState(false);
  const [isEyeDialogOpen, setEyeDialogOpen] = useState(false);
  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<IUserDetail>({
    firstName: "",
    lastName: "",
    userName: "",
    name: "",
    profileImage: "",
    email: "",
    city: "",
    state: "",
    country: "",
    locationId: "",
    availability: "",
    membership: "",
  });

  const theme = useTheme();

  useEffect(() => {
    let param: { search?: string; limit: number; page: number } = {
      limit: pagination.recordPerPage,
      page: pagination.currentPage,
    };
    if (search !== "") {
      param.search = search;
    }
    // setLoading(true);
    getUser(param)
      .then((response) => {
        const data = response.data;

        setPagination({
          ...pagination,
          currentPage: data.users.currentPage,
          totalPage: data.users.totalPage,
          recordPerPage: data.users.usersPerPage,
          availableRecords: data.users.totalAvailableUsers,
        });
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

    // }
  }, [isRefreashPage, search]);

  const updateDetails = async (data: any) => {
    try {
      const response = await updateUserProfile(data);
      toast.success(response.message.html);
      getUserProfileInfo(userDetails.userName);
      setIsRefreshPage(!isRefreashPage);
      setEditProfileDialogOpen(false);
    } catch (error: any) {
      toast.error(error.message.html);
    }
  };

  const handleDeleteClick = (id: number) => {
    setDeleteUserId(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setUserData(userData);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteUser(deleteUserId);
      toast.success(response.message.html);
      setIsRefreshPage(!isRefreashPage);
      handleCloseDeleteDialog();
    } catch (error: any) {
      toast.error(error.message.html);
    }
  };
  const handleStatusUpdate = async (userName: string, status: string) => {
    try {
      const response = await statusUpdate(userName, status);
      toast.success(response.message.html);
      setIsRefreshPage(!isRefreashPage);
      handleClose();
    } catch (error: any) {
      toast.error(error.message.html);
    }
  };

  const handleClick = (event: any, data: { name: string; status: string }) => {
    setAnchorEl(event.currentTarget);
    setSelectDropDown({
      ...selectDropDown,
      userName: data.name,
      status: data.status,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleComposeMailClick = (email: string) => {
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=&body=`;
    window.open(gmailLink, "_blank");
  };

  const dropdownOptions = ["Active", "Inactive"];

  const handleChangePage = (_: unknown, newPage: number) => {
    setPagination({ ...pagination, currentPage: newPage + 1 });
    setIsRefreshPage(!isRefreashPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination({ ...pagination, recordPerPage: Number(event.target.value) });
    setIsRefreshPage(!isRefreashPage);
  };

  const handleEyeIconClick = (userName: string) => {
    getUserProfileInfo(userName);
    setEyeDialogOpen(true);
  };

  const handleEditProfile = () => {
    setEditProfileDialogOpen(true);
  };

  const getUserProfileInfo = async (userName: string) => {
    try {
      const response = await getUserProfile({ userName: userName });
      setUserDetails(response.data);
    } catch (error: any) {
      toast.error(error.message.html);
    }
  };

  const handleUpdateDetails = (editData: IUserDetail) => {
    const { availability, membership, name, ...data } = editData;
    updateDetails(data);
  };

  const handleCloseEditProfile = () => {
    setEditProfileDialogOpen(false);
  };

  const handleCloseViewDetails = () => {
    setEyeDialogOpen(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell className="font-semibold" align="left">
              No
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Username
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Email
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Position
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Location
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Membership
            </TableCell>
            <TableCell className="font-semibold" align="center">
              Status
            </TableCell>
            <TableCell className="font-semibold" align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData &&
            userData?.users?.results?.map(
              (user: {
                sl_no: number;
                id: number;
                name: string;
                email: string;
                position: string;
                location: string;
                membership:string;
                country: string;
                status: string;
                isView: boolean;
              }) => {
                return (
                  <TableRow
                    key={user.id}
                    sx={{
                      "& td, & th": {
                        borderBottom: "1px solid #F2F2F2",
                      },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {user.sl_no}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {user.name}
                    </TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">{user.position}</TableCell>
                    <TableCell align="left">{user.country}</TableCell>
                    <TableCell align="left">{user.membership}</TableCell>
                    <TableCell align="center">
                      {user.status && user.status === "Registration" ? (
                        <Button
                          sx={{
                            textTransform: "none",
                            borderRadius: "20px",
                            boxShadow: "none",
                            color: "#3DB16D",
                            backgroundColor: "#D8F7E5",
                            "&:hover": {
                              backgroundColor: "#D8F7E5",
                            },
                          }}
                        >
                          {user.status}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            variant="contained"
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                              textTransform: "none",
                              borderRadius: "20px",
                              boxShadow: "none",
                              backgroundColor:
                                user.status === "Active"
                                  ? "#D8F7E5;"
                                  : "#F7DAD8",
                              color:
                                user.status === "Active"
                                  ? "#3DB16D;"
                                  : "#F66450",
                              "&:hover": {
                                backgroundColor:
                                  user.status === "Active"
                                    ? "#D8F7E5;"
                                    : "#F7DAD8",
                              },
                            }}
                            onClick={(event) => handleClick(event, user)}
                          >
                            {user.status}
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            {dropdownOptions.map(
                              (option) =>
                                option !== selectDropDown.status && (
                                  <MenuItem
                                    key={option}
                                    onClick={() => {
                                      // handleOptionSelect(option);
                                      handleStatusUpdate(
                                        selectDropDown.userName,
                                        option
                                      );
                                    }}
                                  >
                                    {option}
                                  </MenuItem>
                                )
                            )}
                          </Menu>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Fab
                          sx={{
                            backgroundColor: "white",
                            boxShadow: "none",
                            border: "1px solid #CCCCCC",
                          }}
                          size="small"
                          aria-label="add"
                          // onClick={() => handleUserProfileClick(user.id)}
                          onClick={() => handleEyeIconClick(user.name)}
                          disabled={!user.isView}
                        >
                          <img src={eyeIcon} alt="Eye Icon" />
                        </Fab>

                        <Fab
                          sx={{
                            backgroundColor: "white",
                            boxShadow: "none",
                            border: "1px solid #CCCCCC",
                          }}
                          size="small"
                          aria-label="add"
                          onClick={() => handleComposeMailClick(user.email)}
                        >
                          <img src={mailIcon} alt="Mail Icon" />
                        </Fab>
                        <Fab
                          sx={{
                            backgroundColor: "white",
                            boxShadow: "none",
                            border: "1px solid #CCCCCC",
                          }}
                          aria-label="add"
                          size="small"
                          onClick={() => handleDeleteClick(user.id)}
                        >
                          <img src={trashIcon} alt="Trash Icon" />
                        </Fab>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={pagination.availableRecords}
        page={pagination.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.recordPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<span style={{ fontWeight: "bold" }}>Page:</span>}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{
          marginTop: theme.spacing(2),
          "& .MuiTablePagination-caption": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-select": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-toolbar": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-displayedRows": {
            fontWeight: "bold",
          },
        }}
      />
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDelete}
      />
      {userDetails && (
        <ViewUserDialog
          open={isEyeDialogOpen}
          onClose={handleCloseViewDetails}
          userDetails={userDetails}
          handleEditProfile={handleEditProfile}
        />
      )}

      {userDetails && (
        <EditUserDialog
          open={editProfileDialogOpen}
          onClose={handleCloseEditProfile}
          userDetails={userDetails}
          handleUpdateDetails={(updatedData: IUserDetail) =>
            handleUpdateDetails(updatedData)
          }
        />
      )}
    </div>
  );
};
export default ActiveUserTable;
