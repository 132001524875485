import React from "react";
import signInlogo from "../../assets/images/signInlogo.png";
import AdminBg from "../../assets/images/AdminBg.png";
import { Box, Grid } from "@mui/material";
import { Outlet , Link} from "react-router-dom";

const siteUrl = process.env.REACT_APP_MARKETING_URL;

const BasicStructure = () => {
  return (
    <Box
      className="bg-seaction"
      sx={{
        backgroundImage: `url(${AdminBg})`,
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid container sx={{ alignItems: "center", height: "100%" }}>
        <Grid item lg={6} xs={12} sx={{ textAlign: "center" }}>

        {siteUrl ? (
        <Link to={siteUrl} target="blank">
          <img src={signInlogo} alt="favicon" />
        </Link>
      ) : ('')}
        
        
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: { xs: "1rem", lg: "0rem" },
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicStructure;
