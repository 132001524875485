import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  Typography,
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import { IUserDetail } from "../../interface/apiInterface/IUser";
import uploadCloud from "../../assets/images/uploadCloud.png";
import defaultProfileImage from "../../assets/images/defaultProfileImage.png";
import { getLocationId, updateProfileImage } from "../../service/User/user.api";
import mapPin from "../../assets/images/mapPin.png";
import { toast } from "react-toastify";
type Props = {
  open: boolean;
  onClose: () => void;
  userDetails: IUserDetail;
  handleUpdateDetails: (updatedData: IUserDetail) => void;
};

interface AutocompleteOption {
  id: string;
  label: string;
}

const EditUserDialog = ({
  open,
  onClose,
  userDetails,
  handleUpdateDetails,
}: Props) => {
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteOption[]
  >([]);

  const [editData, setEditData] = useState<IUserDetail>({
    firstName: "",
    lastName: "",
    userName: "",
    profileImage: "",
    name: "",
    email: "",
    availability: "",
    membership: "",
    city: "",
    state: "",
    country: "",
    locationId: "",
  });

  useEffect(() => {
    editValues(userDetails);
  }, [userDetails]);

  const editValues = (userDetails: IUserDetail) => {
    setEditData((predata) => ({
      ...predata,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      userName: userDetails.userName,
      profileImage: userDetails.profileImage,
      email: userDetails.email,
      availability: userDetails.availability,
      membership: userDetails.membership,
      city: userDetails.city,
      state: userDetails.state,
      country: userDetails.country,
      locationId: userDetails.locationId,
    }));
    setSelectedLocation({
      label: `${userDetails.city || ""}, ${userDetails.state || ""}, ${
        userDetails.country || ""
      }`,
    });
  };

  const clearSelection = () => {
    setAutocompleteOptions([]);
  };

  const handleLocation = (e: any, newValue: AutocompleteOption | null) => {
    if (newValue === null) {
      clearSelection();
    } else {
      setSelectedLocation(newValue);
      parseLocationLabel(newValue.label);
      setEditData((prevData) => ({
        ...prevData,
        locationId: newValue.id,
      }));
    }
  };

  const parseLocationLabel = (label: any) => {
    const parts = label?.split(",").map((part: string) => part.trim());
    let city: null, state: string | null, country: null;

    if (parts.length === 6) {
      city = parts[0];
      state = parts[1] + ", " + parts[2] + "," + parts[3] + "," + parts[4];
      country = parts[5];
    } else if (parts.length === 5) {
      city = parts[0];
      state = parts[1] + ", " + parts[2] + "," + parts[3];
      country = parts[4];
    } else if (parts.length === 4) {
      city = parts[0];
      state = parts[1] + ", " + parts[2];
      country = parts[3];
    } else if (parts.length === 3) {
      city = parts[0];
      state = parts[1];
      country = parts[2];
    } else if (parts.length === 2) {
      city = null;
      state = parts[0];
      country = parts[1];
    } else if (parts.length === 1) {
      city = null;
      state = null;
      country = parts[0];
    } else {
      city = null;
      state = null;
      country = null;
    }

    // setSelectedLocation(`${city} ${state} ${country}`);

    setEditData((preData: any) => ({
      ...preData,
      city: city,
      state: state,
      country: country,
    }));

    return {
      City: city,
      State: state,
      Country: country,
    };
  };
  const uploadAllImage = (e: any, type: any) => {
    if (type === "Profile_image") {
      document.getElementById(`icon_profile_icon`)?.click();
    }
  };
  const reUpload = async (data: FormData) => {
    try {
      const response = await updateProfileImage(data);

      setEditData((prevData) => ({
        ...prevData,
        profileImage: response.data,
      }));
      toast.success(response.message.html);
      // setIsRefreshPage(!isRefreashPage);
    } catch (error: any) {
      toast.error(error.message.html);
    }
  };

  const handleFileUpload = async (e: any, type: any) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.error("Unsupported File type ");
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          if (type === "profileImage") {
            if (image.width > 500 || image.height > 500) {
              toast.error("Image dimensions are large");
              return;
            }
            if (image.width <= 500 && image.height <= 500) {
              const formData = new FormData();
              formData.append("file", file);
              if (editData.profileImage) {
                formData.append("imagePath", editData.profileImage);
              }
              reUpload(formData);
            } else {
              toast.error("Image dimensions are large");
              return;
            }
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = async (e: any, type: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.error("Unsupported File type ");
        return;
      }
      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          if (type === "profileImage") {
            if (image.width > 500 || image.height > 500) {
              toast.error("Image dimensions are large");
              return;
            }
            const formData = new FormData();
            formData.append("file", file);
            if (editData.profileImage) {
              formData.append("imagePath", editData.profileImage);
            }

            reUpload(formData);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    const trimmedValue = value.replace(/^\s+/, "").replace(/\s+/g, " ");

    setEditData((preData) => ({
      ...preData,
      [name]: trimmedValue,
    }));
  };
  const getLocationInfo = async (location: string) => {
    try {
      const response = await getLocationId(location);
      setAutocompleteOptions(response.data);
      // setData(response.data);
    } catch (error: any) {
      // toast.error(error.message.html);
    }
  };
  const handleCityStateCountry = (e: any, newValue: string) => {
    if (newValue.length > 4) {
      getLocationInfo(newValue);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "784px",
            height: "auto",
            padding: "30px",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#2CB512",
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "'Onest', sans-serif !important",
          }}
        >
          Edit user
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "16px", fontWeight: 400 }}>
            Edit user name Details
          </DialogContentText>
          <DialogContentText sx={{ marginBottom: 2 }}>
            user will notify by email that admin changed your profile
          </DialogContentText>
          <Grid>
            <DialogContentText
              sx={{
                color: "#2CB512",
                fontSize: "18px",
                fontWeight: 600,
                fontFamily: "'Onest', sans-serif !important",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>Availability:</Grid>
                <Grid item>{editData.availability}</Grid>
              </Grid>
            </DialogContentText>
            <DialogContentText>
              <Grid container>
                <Grid item xs={12} sm={12} md={1.8} lg={1.8}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: "left",
                      mt: "15px",
                    }}
                  >
                    Profile Image
                  </Typography>
                  {editData.profileImage !== null &&
                  editData.profileImage !== "" ? (
                    <Box
                      className="pro-image"
                      sx={{
                        // width: "170px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "170px",
                        },
                        height: "130px",
                        m: "10px 10px 10px 0px",
                      }}
                    >
                      <img
                        src={editData?.profileImage}
                        alt="profileimg"
                        className="img-fluid profile-pic"
                      />
                    </Box>
                  ) : (
                    <Box
                      className="pro-image"
                      sx={{
                        width: "170px",
                        height: "150px",
                        mt: 3,
                      }}
                    >
                      <img
                        src={defaultProfileImage}
                        alt="profileimg"
                        className="img-fluid profile-pic"
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={10.2} lg={10.2}>
                  <Box sx={{ mt: "34px", ml: "0px" }} className="pimg-sec">
                    <Box
                      className="p-image imageBox"
                      sx={{
                        // width:"620px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "620px",
                        },
                        height: "100px",
                        textAlign: "center",
                        pt: "25px",
                      }}
                      onClick={(e) => uploadAllImage(e, "Profile_image")}
                      onDrop={(e) => handleDrop(e, "profileImage")}
                      onDragOver={handleDragOver}
                    >
                      <>
                        <img
                          src={uploadCloud}
                          alt="img"
                          className="img-fluid upload-button"
                        />
                        <input
                          className="file-upload"
                          id="icon_profile_icon"
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleFileUpload(e, "profileImage")}
                        />
                      </>

                      <Typography
                        className="note"
                        sx={{
                          color: "var(--Primary, #2CB512)",
                          textAlign: "center",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "18px",
                          fontFamily: "'Onest', sans-serif !important",
                        }}
                      >
                        Drag & Drop, or Click to upload
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--gray-400, #98A2B3)",
                          textAlign: "center",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "18px",
                          fontFamily: "'Onest', sans-serif !important",
                        }}
                      >
                        JPG, PNG Format (max. 500*500)
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                  }}
                >
                  First Name
                </Typography>
                <TextField
                  className="formFields"
                  sx={{
                    // width: "350px",
                    width: { xs: "100%" },
                    "&:hover": {
                      borderColor: "red",
                    },
                  }}
                  name="firstName"
                  placeholder="Enter the First Name"
                  value={editData?.firstName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                    // paddingLeft: "30px",
                    // paddingLeft: {
                    //   xs: "0px",
                    //   sm: "20px",
                    //   md: "0",
                    //   lg: "30px",
                    // },
                  }}
                >
                  Last Name
                </Typography>
                <TextField
                  className="formFields"
                  type="text"
                  sx={{
                    mt: 0,
                    // width: "350px",
                    width: { xs: "100%" },
                    //  ml: "25px"
                    ml: { xs: "0px" },
                  }}
                  name="lastName"
                  placeholder="Enter the Last Name"
                  value={editData.lastName || ""}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  className="formFields"
                  sx={{
                    // width: "350px",
                    width: { xs: "100%" },
                    "&:hover": {
                      borderColor: "red",
                    },
                  }}
                  name="email"
                  placeholder="Enter the Email"
                  value={editData.email || ""}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                    // paddingLeft: "30px",
                    // paddingLeft: {
                    //   xs: "0px",
                    //   sm: "20px",
                    //   md: "30px",
                    //   lg: "30px",
                    // },
                  }}
                >
                  User Name
                </Typography>
                <TextField
                  className="formFields"
                  type="text"
                  sx={{
                    mt: 0,
                    // width: "350px",
                    width: { xs: "100%" },
                    //  ml: "25px"
                  }}
                  name="userName"
                  placeholder="Enter the User Name"
                  value={userDetails.userName || ""}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                  }}
                >
                  Location
                </Typography>
                <Autocomplete
                  value={selectedLocation || null}
                  onChange={(e, newValue) => handleLocation(e, newValue)}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    handleCityStateCountry(event, newInputValue);
                    if (newInputValue.trim() === "") {
                      clearSelection();
                      setSelectedLocation(null);
                    }
                  }}
                  options={autocompleteOptions}
                  getOptionLabel={(option) =>
                    option && option.label ? option.label : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option &&
                    option.id === value.id &&
                    option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formFields"
                      sx={{
                        // width: "350px",
                        width: { xs: "100%" },
                        "&:hover": {
                          borderColor: "red",
                        },
                      }}
                      name="location"
                      placeholder="City, State, Country"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={mapPin}
                              alt="mapPin"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={selectedLocation ? selectedLocation.label : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: "left",
                    mt: "15px",
                    // paddingLeft: "30px",
                    // paddingLeft: {
                    //   xs: "0px",
                    //   sm: "20px",
                    //   md: "30px",
                    //   lg: "30px",
                    // },
                  }}
                >
                  Membership
                </Typography>
                <TextField
                  className="formFields"
                  type="text"
                  sx={{
                    mt: 0,
                    // width: "350px",
                    width: { xs: "100%" },
                    ml: { xs: "0px" },
                  }}
                  name="membership"
                  placeholder="Enter the Last Name"
                  value={editData.membership || ""}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={onClose}
            sx={{
              textTransform: "none",
              fontFamily: '"Onest", sans-serif !important',
              borderRadius: "10px",
              border: "1px solid #2CB512",
              color: "#2CB512",
              backgroundColor: "#FFF",
              marginRight: "8px",
              width: "363.5px",
              marginBottom: "20px",
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateDetails(editData)}
            sx={{
              textTransform: "none",
              fontFamily: '"Onest", sans-serif !important',
              backgroundColor: "#2CB512",
              borderRadius: "10px",
              color: "#FFF",
              width: "363.5px",
              marginBottom: "20px",
              "&:hover": {
                backgroundColor: "#2CB512",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditUserDialog;
