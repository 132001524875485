import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import dashboardIcon from "../../assets/icons/dashboardIcon.svg";
import activeDashboardIcon from "../../assets/icons/activeDashboardIcon.svg";
import planIcon from "../../assets/icons/planIcon.svg";
import activePlanIcon from "../../assets/icons/activePlanIcon.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import activeUserIcon from "../../assets/icons/activeUserIcon.svg";
import discountIcon from "../../assets/icons/discountIcon.svg";
import activeDiscountIcon from "../../assets/icons/activeDiscountIcon.svg";
import skillIcon from "../../assets/icons/skillIcon.svg";
import activeSkillIcon from "../../assets/icons/activeSkillIcon.svg";
import toolIcon from "../../assets/icons/toolIcon.svg";
import activeToolIcon from "../../assets/icons/activeToolIcon.svg";
import changePassword from "../../assets/icons/changePassword.svg";
import logoutIcon from "../../assets/icons/logoutIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import ChangePassword from "./changePassword";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [openChangePassword, setOpenChangePassword] = useState(false);

  const sideBar = [
    {
      name: "Dashboard",
      path: "/",
      icon: dashboardIcon,
      activeIcon: activeDashboardIcon,
    },
    {
      name: "Plan Management",
      path: "/plan",
      icon: planIcon,
      activeIcon: activePlanIcon,
    },
    {
      name: "Users",
      path: "/user",
      icon: userIcon,
      activeIcon: activeUserIcon,
    },
    {
      name: "Discount Code",
      path: "/discount",
      icon: discountIcon,
      activeIcon: activeDiscountIcon,
    },
    {
      name: "Skills",
      path: "/skill",
      icon: skillIcon,
      activeIcon: activeSkillIcon,
    },
    {
      name: "Tools",
      path: "/tool",
      icon: toolIcon,
      activeIcon: activeToolIcon,
    },
    {
      name: "Change Password",
      path: "change-password",
      icon: changePassword,
      activeIcon: activeDiscountIcon,
    },
    {
      name: "Logout",
      path: "/logout",
      icon: logoutIcon,
      activeIcon: logoutIcon,
    },
  ];
  const handleActive = (path: string) => {
    if (location.pathname === "/" && path === "/") return true;
    else if (path.length > 2) return location.pathname.includes(path);
  };
  const handleRoute = (path: string) => {
    if (path === "change-password") {
      setOpenChangePassword(true);
    } else {
      navigate(path);
    }
  };

  const closeDialog = () => {
    setOpenChangePassword(false);
  };

  return (
    <Box sx={{ mt: 8 }}>
      <ChangePassword open={openChangePassword} close={closeDialog} />

      {sideBar.map((item, index) => (
        <Box
          onClick={() => handleRoute(item.path)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          key={index}
        >
          <span
            className={handleActive(item.path) ? "activeBorder" : ""}
          ></span>
          <Box
            className={handleActive(item.path) ? "activeSidebar" : "sidebar"}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={handleActive(item.path) ? item.activeIcon : item.icon}
              alt={item.name}
            />
            <Typography
              sx={{
                fontFamily: "'Onest', sans-serif",
                color: handleActive(item.path) ? "white" : "#475467",
                ml: 1,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SideBar;
