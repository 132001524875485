import { AxiosError } from "axios";
import api from "./api";

export const getDiscountPlan = () => {
  return api
    .get("/plan/getPromoValidity")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const promoCodePlan = (data: { promoId: number; email: string[] }) => {
  return api
    .post("/dashboard/promoCode", {
      promoId: data.promoId,
      email: data.email,
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
