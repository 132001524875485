import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type Props = {
  basic: {
    total: number;
    active: number;
    inactive: number;
    invited: number;
  };
  premium: { total: number; active: number; inactive: number };
};

const UserType = ({ basic, premium }: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ px: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Card className="userType">
      <Box p={2}>
        <Typography className="font-semibold" sx={{ fontSize: "20px" }}>
          User type
        </Typography>
        <Box sx={{ borderColor: "inherit" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Basic"  {...a11yProps(0)} />
            <Tab label="Premium" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {Object.entries(basic).map(([key, value]) =>
            key !== "total" ? (
              <Box pt={2} key={key}>
                <Typography
                  className="font-medium"
                  sx={{
                    fontSize: "18px",
                    color: "#475467",
                    textTransform: "capitalize",
                  }}
                >
                  {key + " user"}
                </Typography>
                <Typography className="font-bold" sx={{ fontSize: "36px" }}>
                  {value}
                </Typography>
              </Box>
            ) : (
              ""
            )
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {Object.entries(premium).map(([key, value]) =>
            key !== "total" ? (
              <Box pt={2} key={key}>
                <Typography
                  className="font-medium"
                  sx={{
                    fontSize: "18px",
                    color: "#475467",
                    textTransform: "capitalize",
                  }}
                >
                  {key + " user"}
                </Typography>
                <Typography className="font-bold" sx={{ fontSize: "36px" }}>
                  {value}
                </Typography>
              </Box>
            ) : (
              ""
            )
          )}
        </CustomTabPanel>
      </Box>
    </Card>
  );
};

export default UserType;
