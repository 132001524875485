import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Avatar,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import mapPin from "../../assets/icons/mapPin.svg";
import { IUserDetail } from "../../interface/apiInterface/IUser";

type Props = {
  open: boolean;
  onClose: () => void;
  userDetails: IUserDetail;
  handleEditProfile: () => void;
};

const renderButton = (
  label: string,
  onClick: () => void,
  backgroundColor: string,
  isCancel: boolean
) => {
  return (
    <Button
      onClick={isCancel ? onClick : onClick}
      sx={{
        textTransform: "none",
        fontFamily: '"Onest", sans-serif !important',
        backgroundColor: backgroundColor,
        borderRadius: "10px",
        color: isCancel ? "#FFF" : "#2CB512",
        width: { xs: "100%", sm: "356px" },
        height: { sm: "44px" },
        marginBottom: "5px",
        marginRight: isCancel ? "8px" : 0,
        border: !isCancel ? "1px solid #2CB512" : "none",
        "&:hover": {
          backgroundColor: isCancel ? "#2CB512" : "#fff",
        },
      }}
    >
      {label}
    </Button>
  );
};

const ViewUserDialog = ({
  open,
  onClose,
  userDetails,
  handleEditProfile,
}: Props) => {
  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "500px",
            height: "auto",
            padding: "20px",
            justifyContent: "center !important",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#12B76A",
            textAlign: "center",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "21.6px",
            fontFamily: '"Onest", sans-serif !important',
          }}
        >
          Availability: {userDetails?.availability}
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          <Grid container sx={{ mt: 3 }} className="view-dialog">
            <Grid item lg={12} sm={12} xs={12} className="availLogo">
              <Avatar
                alt="User Image"
                src={userDetails?.profileImage}
                sx={{ width: 120, height: 120, borderRadius: "10px" }}
              />
              <Box
                className="text-avbl"
                sx={{
                  backgroundColor:
                    userDetails?.membership === "Premium"
                      ? "#d1fadf"
                      : "#F2F4F7",
                  color:
                    userDetails?.membership === "Premium" ? "#12b76a" : "#000",
                }}
              >
                {userDetails?.membership}
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              sx={{ ml: 2, textAlign: "center" }}
              className="margleft"
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  fontFamily: '"Onest", sans-serif !important',
                }}
              >
                {userDetails?.firstName} {userDetails?.lastName}
              </Typography>
              <Typography
                sx={{
                  color: "#101828",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  fontFamily: '"Onest", sans-serif !important',
                }}
              >
                {userDetails?.userName} | {userDetails?.email}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  fontFamily: '"Onest", sans-serif !important',
                }}
              >
                <img
                  className="mapImg"
                  src={mapPin}
                  alt="mapPin"
                  style={{ width: "20px", height: "20px" }}
                />
                {userDetails?.city} {userDetails?.city === null ? "" : ","}
                {userDetails.state}
                {userDetails?.state === null ? "" : ","}
                {userDetails.country}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {renderButton("Cancel", onClose, "#fff", false)}
          {renderButton("Edit User", handleEditProfile, "#2CB512", true)}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewUserDialog;
