import { Box, Card, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { userGraph } from "../../service/dashboard.api";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [date, setDate] = useState(dayjs(new Date()));
  const [chart, setChart] = useState({
    basic: {},
    premium: {},
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await userGraph(dayjs(date).year());
        setChart(response.data.data);
        // toast.success(response.data.message.html);
      } catch (error: any) {
        toast.error(error.response.data.message.html);
      }
    }

    fetchData();
  }, [date]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 5,
          precision: 0, // Set the step size to 1 to display whole numbers
        },
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Basic",
        data: Object.values(chart.basic),
        borderColor: "rgba(44, 181, 18, 1)",
        backgroundColor: "rgba(44, 181, 18, 0.5)",
      },
      {
        label: "Premium",
        data: Object.values(chart.premium),
        borderColor: "rgba(117, 59, 182, 1)",
        backgroundColor: "rgba(117, 59, 182, 0.5)",
      },
    ],
  };

  const handleDateChange = (newValue: string) => {
    setDate(JSON.parse(newValue));
  };
  return (
    <Card>
      <Box p={2}>
        <Stack
        className="regUser"
          sx={{ p: 1 }}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography className="font-semibold" sx={{ fontSize: "20px" }}>
            Registered Users
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                sx={{ width: "10px" }}
                openTo="year"
                views={["year"]}
                value={date}
                onChange={(newValue) =>
                  handleDateChange(JSON.stringify(newValue))
                }
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
        <Line options={options} data={data} />
      </Box>
    </Card>
  );
};

export default BarChart;
