import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { updatePassword } from "../../service/User/user.api";
import { toast } from "react-toastify";
import "./style.css";

type ShowPasswordState = {
  oldPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
};

const ChangePassword = ({ open, close }: any) => {
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState<ShowPasswordState>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleField = (e: any) => {
    const { name, value } = e.target;
    setPassword((preData) => ({
      ...preData,
      [name]: value,
    }));

    setError((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  const handleChangePassword = async () => {
    const { oldPassword, newPassword, confirmPassword } = password;

    if (oldPassword === "") {
      setError((preData) => ({
        ...preData,
        oldPassword: "Old Password is required",
      }));
      return;
    }
    if (newPassword === "") {
      setError((preData) => ({
        ...preData,
        newPassword: "New Password is required",
      }));
      return;
    }
    if (confirmPassword === "") {
      setError((preData) => ({
        ...preData,
        confirmPassword: "Confirm Password is required",
      }));
      return;
    }
    if (newPassword !== confirmPassword) {
      setError((preData) => ({
        ...preData,
        confirmPassword: "Passwords do not match",
      }));
      return;
    }
    try {
      const res = await updatePassword(
        password.oldPassword,
        password.newPassword
      );
      toast.success(res.message.value);
      closeDialog();
    } catch (error: any) {
      toast.error(error.response.data.message.value);
    }
  };

  const handleClickShowPassword = (type: keyof ShowPasswordState) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [type]: !prevShowPassword[type],
    }));
  };

  const handleMouseDownPassword = (type: keyof ShowPasswordState) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [type]: !prevShowPassword[type],
    }));
  };

  const closeDialog = () => {
    setPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    close();
  };

  const renderButton = (
    label: string,
    onClick: () => void,
    backgroundColor: string,
    isCancel: boolean
  ) => {
    return (
      <Button
        onClick={isCancel ? onClick : onClick}
        sx={{
          textTransform: "none",
          fontFamily: '"Onest", sans-serif !important',
          backgroundColor: backgroundColor,
          borderRadius: "10px",
          color: isCancel ? "#FFF" : "#2CB512",
          width: { xs: "100%", sm: "356px" },
          marginBottom: "20px",
          marginRight: isCancel ? "8px" : 0,
          border: !isCancel ? "1px solid #2CB512" : "none",
          "&:hover": {
            backgroundColor: isCancel ? "#2CB512" : "#fff",
          },
        }}
      >
        {label}
      </Button>
    );
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle
        sx={{
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "28px",
          color: "#2CB512",
          fontFamily: '"Onest", sans-serif !important',
        }}
      >
        Change admin password
      </DialogTitle>
      <DialogContent className="ChangeAdmin">
        <DialogContentText
          sx={{
            marginBottom: "40px",
            fontSize: "16px",
            fontWeight: 400,
            gap: "8px",
            fontFamily: '"Onest", sans-serif !important',
          }}
        >
          Admin password is Most important so keep it private
        </DialogContentText>
        <Grid>
          <Typography
            className="lable-name"
            sx={{
              textAlign: "left",
              mt: "15px",
            }}
          >
            Current password
          </Typography>
          <TextField
            className="formFields"
            type={showPassword.oldPassword ? "text" : "password"}
            sx={{
              mt: 0,
              width: { xs: "100%" },
              ml: { xs: "0px" },
            }}
            name="oldPassword"
            placeholder="Enter the current password"
            value={password.oldPassword || ""}
            onChange={handleField}
            helperText={
              <span style={{ color: "red" }}>{error.oldPassword}</span>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("oldPassword")}
                    onMouseDown={() => handleMouseDownPassword("oldPassword")}
                  >
                    {showPassword.oldPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className="lable-name"
            sx={{
              textAlign: "left",
              mt: "15px",
            }}
          >
            New Password
          </Typography>
          <TextField
            className="formFields"
            type={showPassword.newPassword ? "text" : "password"}
            sx={{
              mt: 0,
              width: { xs: "100%" },
              ml: { xs: "0px" },
            }}
            name="newPassword"
            placeholder="Enter the new password"
            value={password.newPassword || ""}
            helperText={
              <span style={{ color: "red" }}>{error.newPassword}</span>
            }
            onChange={handleField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("newPassword")}
                    onMouseDown={() => handleMouseDownPassword("newPassword")}
                  >
                    {showPassword.newPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className="lable-name"
            sx={{
              textAlign: "left",
              mt: "15px",
            }}
          >
            Confirm Password
          </Typography>
          <TextField
            className="formFields"
            type={showPassword.confirmPassword ? "text" : "password"}
            sx={{
              mt: 0,
              width: { xs: "100%" },
              ml: { xs: "0px" },
            }}
            name="confirmPassword"
            placeholder="Enter the new password"
            value={password.confirmPassword || ""}
            onChange={handleField}
            helperText={
              <span style={{ color: "red" }}>{error.confirmPassword}</span>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("confirmPassword")}
                    onMouseDown={() =>
                      handleMouseDownPassword("confirmPassword")
                    }
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions className="changeadminBtn">
        {renderButton("Cancel", closeDialog, "#fff", false)}
        {renderButton("Save", handleChangePassword, "#2CB512", true)}
      </DialogActions>
    </Dialog>
  );
};

export default ChangePassword;
