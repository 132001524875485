import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import "./style.css";
import editIcon from "../../assets/icons/editIcon.svg";
import trashIcon from "../../assets/icons/trashIcon.svg";
import CategoryDialog from "./categoryDialog";
import ToolDialog from "./ToolDialog";
import {
  addCategory,
  addTool,
  deleteCategory,
  deleteRole,
  editCategory,
  editTool,
  getCategory,
  getSearchToolList,
  getToolList,
} from "../../service/tool.api";
import DeleteDialog from "../../components/deletePopUp";
import { ICategoryDetail } from "../../interface/uiInterface/ISkill";
import { IToolDetail } from "../../interface/uiInterface/ITool";
import { toast } from "react-toastify";

const Tool = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setSearch] = useState("");

  const [openTool, setOpenTool] = React.useState(false);
  const handleOpenTool = () => setOpenTool(true);
  const handleCloseTool = () => setOpenTool(false);

  const [toolList, setToolList] = useState<IToolDetail[]>([]);
  const [categoryList, setCategoryList] = useState<ICategoryDetail[]>([]);
  const [createCategory, setCreateCategory] = useState({
    category: "",
    key: "",
    isEdit: false,
  });

  const [isAddRole, setIsAddRole] = useState(false);
  const [category, setCategory] = useState({
    name: "",
    categoryKey: "",
    toolId: 0,
  });
  const [openDeletePopup, setDeletePopup] = useState({
    isCategory: false,
    openPopup: false,
    categoryKey: "",
    toolId: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dynamicValues, setDynamicValues] = useState({
    categoryKey: "",
  });
  useEffect(() => {
    handleToolDetail("");
    handleCategoryList();
  }, []);

  const handleToolDetail = async (searchValue: any) => {
    setLoading(true);
    try {
      if (searchValue.trim() === "" || searchValue === null) {
        // If search is empty, fetch all data
        await getToolList()
          .then((res) => {
            setToolList(res.data.data);
          })
          .catch((error) => {
            console.error("ERROR: get Tool details", error);
          });
      } else {
        // If search is not empty, fetch filtered data
        await getSearchToolList(searchValue)
          .then((res) => {
            setToolList(res.data.data);
          })
          .catch((error) => {
            console.error("ERROR: get Tool details", error);
          });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleCategoryList = async () => {
    setLoading(true);
    await getCategory()
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {
        console.error("ERROR: get category details", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCategory = async (value: { category: string }) => {
    try {
      if (createCategory.isEdit) {
        const response = await editCategory({
          name: value.category,
          key: createCategory.key,
          isSkill: false,
          isTool: true,
        });

        await handleClose();
        await handleToolDetail(search);
        await handleCategoryList();
        toast.success(response.data.message.html);
      } else {
        const response = await addCategory({ data: [value.category], isSkill: false, isTool: true  });

        await handleClose();
        await handleToolDetail(search);
        await handleCategoryList();
        toast.success(response.data.message.html);
      }
    } catch (error: any) {
      console.error("Error handling category:", error);
      if(error.response.data.message.html !== '' && error.response.data.message.html !== undefined){
        toast.error(error.response.data.message.html);
       }else{        
        toast.error(error.response.data.message);
       }
      // toast.error(error.data.message.html);
    }
  };

  const handleDeleteDialogClose = () => {
    setDeletePopup({
      isCategory: false,
      openPopup: false,
      categoryKey: "",
      toolId: 0,
    });
  };

  const handleDeleteDialog = async () => {
    try {
      if (openDeletePopup.isCategory) {
        const response = await deleteCategory({
          categorykey: openDeletePopup.categoryKey,
          isSkill: false,
        });

        await handleToolDetail(search);
        await handleCategoryList();
        await handleDeleteDialogClose();
        toast.success(response.data.message.html);
      } else {
        const response = await deleteRole({ toolId: openDeletePopup.toolId });

        await handleToolDetail(search);
        await handleCategoryList();
        await handleDeleteDialogClose();
        toast.success(response.data.message.html);
      }
    } catch (error: any) {
      console.error("Error handling deletion:", error);
      toast.error(error.data.message.html);
    }
  };

  const addTodoTool = async (data: { name: string; categoryKey: string }) => {
    try {
      if (isAddRole) {
        const response = await addTool({
          name: data.name,
          categoryKey: data.categoryKey,
        });

        await handleCloseTool();
        await handleToolDetail(search);
        toast.success(response.data.message.html);
      } else {
        const response = await editTool({
          name: data.name,
          categoryKey: data.categoryKey,
          toolId: category.toolId,
        });

        await handleCloseTool();
        await handleToolDetail(search);
        toast.success(response.data.message.html);
      }
    } catch (error: any) {
      if(error.response.data.message.html !== '' && error.response.data.message.html !== undefined){
        toast.error(error.response.data.message.html);
       }else{        
        toast.error(error.response.data.message);
       }
      // toast.error(error.data.message.html);
    }
  };

  const handleAddTool = () => {
    setCreateCategory({
      category: "",
      key: "",
      isEdit: false,
    });
    handleOpen();
  };

  const handleEditCategory = (value: { key: string; label: string }) => {
    setCreateCategory({
      ...createCategory,
      isEdit: true,
      key: value.key,
      category: value.label,
    });
    handleOpen();
  };

  const handleDeleteCategory = async (key: string) => {
    setDeletePopup({
      ...openDeletePopup,
      categoryKey: key,
      openPopup: true,
      isCategory: true,
    });
  };

  const handleAddSubTool = (item: string) => {
    setIsAddRole(true);
    setCategory({
      name: "",
      categoryKey: "",
      toolId: 0,
    });
    setDynamicValues({
      categoryKey: item,
    });
    handleOpenTool();
  };

  const hanldeEditTool = (
    categoryKey: string,
    list: { label: string; id: number }
  ) => {
    setIsAddRole(false);
    setCategory({
      ...category,
      categoryKey: categoryKey,
      name: list.label,
      toolId: list.id,
    });
    handleOpenTool();
  };

  const handleDeleteRole = async (id: number) => {
    setDeletePopup({
      ...openDeletePopup,
      toolId: id,
      openPopup: true,
      isCategory: false,
    });
  };

  return (
    <Box p={3}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Card>
        {open && (
          <CategoryDialog
            open={open}
            handleClose={handleClose}
            handleCategory={handleCategory}
            createCategory={createCategory}
          />
        )}
        {openTool && (
          <ToolDialog
            open={openTool}
            handleClose={handleCloseTool}
            isAddRole={isAddRole}
            categoryList={categoryList}
            addTodo={addTodoTool}
            category={category}
            dynamicValues={dynamicValues}
          />
        )}
        {openDeletePopup.openPopup && (
          <DeleteDialog
            open={openDeletePopup.openPopup}
            onClose={handleDeleteDialogClose}
            onDelete={handleDeleteDialog}
          />
        )}
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            className="formFields"
            placeholder="Search"
            value={search}
            onChange={(event) => {
              const value = event.target.value;
              setSearch(value);
              // Fetch data when search field is changed
              if (value.trim() === "" || value === null) {
                handleToolDetail(value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleToolDetail(search);
              }
            }}
            sx={{ width: "90%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleToolDetail(search)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            className="common-button-green comBtngreen"
            type="submit"
            variant="contained"
            onClick={handleAddTool}
            sx={{ width: "150px", marginLeft: "20px" }}
          >
            Add Tool
          </Button>
        </Box>

        <Box sx={{ margin: 2 }}>
          {toolList.map((item, index) => (
            <Accordion
              sx={{
                backgroundColor: "#F7F8F8",
                marginBottom: 2,
                border: "none",
                boxShadow: "none",
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  className="font-semibold"
                  sx={{
                    fontSize: "20px",
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  {item.category.label}
                </Typography>
                <Typography
                  className="font-semibold"
                  sx={{
                    fontSize: "20px",
                    color: "#2CB512",
                    pl: 1,
                  }}
                >
                  ({item.tools.length >= 1 ? item.tools.length : 0})
                </Typography>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      border: "1px solid #CCCCCC",
                      padding: "4px",
                      mr: 2,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditCategory(item.category);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="Edit Icon"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      mr: 2,
                      boxShadow: "none",
                      border: "1px solid #CCCCCC",
                      padding: "4px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteCategory(item.category.key);
                    }}
                  >
                    <img
                      src={trashIcon}
                      alt="Trash Icon"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      borderColor: "#2CB512",
                      color: "#2CB512",
                      borderRadius: "8px",
                      "&:hover": {
                        borderColor: "#2CB512",
                      },
                      mr: 1,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAddSubTool(item.category.key);
                    }}
                  >
                    Add Sub-Tool
                  </Button>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ borderTop: "1px solid #CCCCCC" }}>
                {item.tools.map((list, index) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ mt: 2 }}
                    key={index}
                  >
                    <Typography
                      className="font-regular"
                      sx={{ color: "#667085", fontSize: "16px" }}
                    >
                      {list.label}
                    </Typography>
                    <Box sx={{ marginLeft: "auto" }}>
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                          padding: "4px",
                        }}
                        onClick={() => hanldeEditTool(item.category.key, list)}
                      >
                        <img
                          src={editIcon}
                          alt="Edit Icon"
                          width={20}
                          height={20}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          ml: 2,
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                          padding: "4px",
                        }}
                        onClick={() => handleDeleteRole(list.id)}
                      >
                        <img
                          src={trashIcon}
                          alt="Trash Icon"
                          width={20}
                          height={20}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default Tool;
