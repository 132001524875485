import { AxiosError } from "axios";
import api from "./api";

export const signIn = (data: { email: string; password: string }) => {
  return api
    .post("/login/", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const forgotPassword = (data: { email: string }) => {
  return api
    .post("/login/forgotpassword", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const resetPassword = (
  resetCode: string | null,
  data: { newPassword: string }
) => {
  return api
    .post(`/login/resetpassword?resetCode=${resetCode}`, data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
