import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import "./style.css";
import editIcon from "../../assets/icons/editIcon.svg";
import trashIcon from "../../assets/icons/trashIcon.svg";
import CategoryDialog from "./categoryDialog";
import RoleDialog from "./roleDialog";
import {
  addCategory,
  addSkill,
  deleteCategory,
  deleteRole,
  editCategory,
  editSkill,
  getCategory,
  getSearchSkillList,
  getSkillList,
} from "../../service/skill.api";
import DeleteDialog from "../../components/deletePopUp";
import {
  ICategoryDetail,
  ISkillDetail,
} from "../../interface/uiInterface/ISkill";
import { toast } from "react-toastify";

const Skills = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openDeletePopup, setDeletePopup] = useState({
    isCategory: false,
    openPopup: false,
    categoryKey: "",
    skillId: 0,
  });
  const [createCategory, setCreateCategory] = useState({
    category: "",
    key: "",
    isEdit: false,
  });
  const [skillList, setSkillList] = useState<ISkillDetail[]>([]);
  const [categoryList, setCategoryList] = useState<ICategoryDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dynamicValues, setDynamicValues] = useState({
    categoryKey: "",
  });

  useEffect(() => {
    handleSkillDetails("");
    handleCategoryList();
  }, []);
  const handleSkillDetails = async (searchValue: any) => {
    setLoading(true);
    try {
      if (searchValue.trim() === "" || searchValue === null) {
        // If search is empty, fetch all data
        await getSkillList()
          .then((res) => {
            setSkillList(res.data.data);
          })
          .catch((error) => {
            console.error("ERROR: get skill details", error);
          });
      } else {
        // If search is not empty, fetch filtered data
        await getSearchSkillList(searchValue)
          .then((res) => {
            setSkillList(res.data.data);
          })
          .catch((error) => {
            console.error("ERROR: get skill details", error);
          });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryList = async () => {
    setLoading(true);
    await getCategory()
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((error) => {
        toast.error("Error in adding Category");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openTool, setOpenTool] = React.useState(false);
  const handleOpenTool = () => setOpenTool(true);
  const handleCloseTool = () => setOpenTool(false);
  const [category, setCategory] = useState({
    name: "",
    categoryKey: "",
    description: "",
    skillId: 0,
  });
  const [isAddRole, setIsAddRole] = useState(false);

  const handleCategory = async (value: { category: string }) => {
    if (createCategory.isEdit) {
      try {
        const response = await editCategory({
          name: value.category,
          key: createCategory.key,
          isSkill: true,
          isTool: false,
        });
        toast.success(response.data.message.html);
        await handleClose();
        await handleSkillDetails(search);
        await handleCategoryList();
      } catch (error: any) {
        toast.error(error.data.message.html);
      }
    } else {
      try {
        const response = await addCategory({ data: [value.category], isSkill: true, isTool: false });
        toast.success(response.data.message.html);
        await handleClose();
        await handleSkillDetails(search);
        await handleCategoryList();
      } catch (error: any) {
        console.error("Error adding category:", error);
        toast.error(error.response.data.message.html);
      }
    }
  };

  const handleAddSkill = () => {
    setCreateCategory({
      category: "",
      key: "",
      isEdit: false,
    });
    handleOpen();
  };
  const handleEditCategory = (value: { key: string; label: string }) => {
    setCreateCategory({
      ...createCategory,
      isEdit: true,
      key: value.key,
      category: value.label,
    });
    handleOpen();
  };

  const addTodoTool = async (data: {
    name: string;
    categoryKey: string;
    description: string;
  }) => {
    try {
      if (isAddRole) {
        const response = await addSkill({
          name: data.name,
          categoryKey: data.categoryKey,
          description: data.description,
        });

        toast.success(response.data.message.html);
        await handleCloseTool();
        await handleSkillDetails(search);
      } else {
        const response = await editSkill({
          name: data.name,
          categoryKey: data.categoryKey,
          description: data.description,
          skillId: category.skillId,
        });

        toast.success(response.data.message.html);
        await handleCloseTool();
        await handleSkillDetails(search);
      }
    } catch (error: any) {
      if(error.response.data.message.html !== '' && error.response.data.message.html !== undefined){
        toast.error(error.response.data.message.html);
       }else{        
        toast.error(error.response.data.message);
       }
    }
  };

  const handleAddSubSkill = (item: string) => {
    setIsAddRole(true);
    setCategory({
      name: "",
      categoryKey: "",
      description: "",
      skillId: 0,
    });
    setDynamicValues({
      categoryKey: item,
    });
    handleOpenTool();
  };

  const hanldeEditRole = (
    categoryKey: string,
    list: { label: string; id: number; description: string }
  ) => {
    setIsAddRole(false);
    setCategory({
      ...category,
      categoryKey: categoryKey,
      name: list.label,
      description: list.description,
      skillId: list.id,
    });
    handleOpenTool();
  };

  const handleDeleteCategory = async (key: string) => {
    setDeletePopup({
      ...openDeletePopup,
      categoryKey: key,
      openPopup: true,
      isCategory: true,
    });
  };

  const handleDeleteRole = async (id: number) => {
    setDeletePopup({
      ...openDeletePopup,
      skillId: id,
      openPopup: true,
      isCategory: false,
    });
  };

  const handleDeleteDialogClose = () => {
    setDeletePopup({
      isCategory: false,
      openPopup: false,
      categoryKey: "",
      skillId: 0,
    });
  };

  const handleDeleteDialog = async () => {
    if (openDeletePopup.isCategory) {
      try {
        const response = await deleteCategory({
          categorykey: openDeletePopup.categoryKey,
          isSkill: true,
        });
        toast.success(response.data.message.html);
        await handleSkillDetails(search);
        await handleCategoryList();
        await handleDeleteDialogClose();
      } catch (error: any) {
        toast.error(error.data.message.html);
      }
    } else {
      try {
        const response = await deleteRole({ skillId: openDeletePopup.skillId });
        toast.success(response.data.message.html);
        await handleSkillDetails(search);
        await handleCategoryList();
        await handleDeleteDialogClose();
      } catch (error: any) {
        toast.error(error.data.message.html);
      }
    }
  };

  return (
    <Box p={3}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Card>
        {open && (
          <CategoryDialog
            open={open}
            handleClose={handleClose}
            handleCategory={handleCategory}
            createCategory={createCategory}
          />
        )}
        {openTool && (
          <RoleDialog
            open={openTool}
            handleClose={handleCloseTool}
            isAddRole={isAddRole}
            categoryList={categoryList}
            addTodo={addTodoTool}
            category={category}
            dynamicValues={dynamicValues}
          />
        )}
        {openDeletePopup.openPopup && (
          <DeleteDialog
            open={openDeletePopup.openPopup}
            onClose={handleDeleteDialogClose}
            onDelete={handleDeleteDialog}
          />
        )}
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            className="formFields"
            placeholder="Search"
            value={search}
            // onChange={(event) => setSearch(event.target.value)}
            onChange={(event) => {
              const value = event.target.value;
              setSearch(value);
              // Fetch data when search field is changed
              if (value.trim() === "" || value === null) {
                handleSkillDetails(value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSkillDetails(search);
              }
            }}
            sx={{ width: "90%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleSkillDetails(search)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            className="common-button-green comBtngreen"
            type="submit"
            variant="contained"
            onClick={handleAddSkill}
            sx={{ width: "150px", marginLeft: "20px" }}
          >
            Add Skill
          </Button>
        </Box>

        <Box sx={{ margin: 2 }}>
          {skillList.map((item, index) => (
            <Accordion
              sx={{
                backgroundColor: "#F7F8F8",
                marginBottom: 2,
                border: "none",
                boxShadow: "none",
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  className="font-semibold"
                  sx={{
                    fontSize: "20px",
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  {item.category.label}
                </Typography>
                <Typography
                  className="font-semibold"
                  sx={{
                    fontSize: "20px",
                    color: "#2CB512",
                    pl: 1,
                  }}
                >
                  ({item.skills.length >= 1 ? item.skills.length : "0"})
                </Typography>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      border: "1px solid #CCCCCC",
                      padding: "4px",
                      mr: 2,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditCategory(item.category);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="Edit Icon"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      mr: 2,
                      boxShadow: "none",
                      border: "1px solid #CCCCCC",
                      padding: "4px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteCategory(item.category.key);
                    }}
                  >
                    <img
                      src={trashIcon}
                      alt="Trash Icon"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      borderColor: "#2CB512",
                      color: "#2CB512",
                      borderRadius: "8px",
                      "&:hover": {
                        borderColor: "#2CB512",
                      },
                      mr: 1,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAddSubSkill(item.category.key);
                    }}
                  >
                    Add Sub-Skill
                  </Button>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ borderTop: "1px solid #CCCCCC" }}>
                {item.skills.map((list, index) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ mt: 2 }}
                    key={index}
                  >
                    <Typography
                      className="font-regular"
                      sx={{ color: "#667085", fontSize: "16px" }}
                    >
                      {list.label}
                    </Typography>
                    <Box sx={{ marginLeft: "auto" }}>
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                          padding: "4px",
                        }}
                        onClick={() => hanldeEditRole(item.category.key, list)}
                      >
                        <img
                          src={editIcon}
                          alt="Edit Icon"
                          width={20}
                          height={20}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          ml: 2,
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                          padding: "4px",
                        }}
                        onClick={() => handleDeleteRole(list.id)}
                      >
                        <img
                          src={trashIcon}
                          alt="Trash Icon"
                          width={20}
                          height={20}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default Skills;
