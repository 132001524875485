import { AxiosError } from "axios";
import api from "../api";
import { IUserDetail } from "../../interface/apiInterface/IUser";

// to get user list

export const getUser = (params: {
  search?: string;
  limit: number;
  page: number;
}) => {
  return api
    .get(`/user/userlist`, { params })
    .then((res) => res.data)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// to get invite user list

export const getInviteUser = (params: {
  search?: string;
  limit: number;
  page: number;
}) => {
  return api
    .get(`/invite/invitelist`, { params })
    .then((res) => res.data)
    .catch((err: AxiosError) => {
      throw err;
    });
};

//invite user
export const inviteUser = async (email: string) => {
  try {
    const response = await api.post("/invite/inviteuser", {
      email: email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//resend invite link
export const resendInvite = async (email: string) => {
  try {
    const response = await api.post("/invite/resentinvite", {
      email: email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//delete User
export const deleteUser = async (id: number) => {
  try {
    const response = await api.delete("/user/deleteuser", {
      data: { id },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// delete invite user
export const deleteInviteUser = async (id: number) => {
  try {
    const response = await api.delete("/invite/deleteinvite", {
      data: { id },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//status update
export const statusUpdate = async (userName: string, newStatus: string) => {
  try {
    const response = await api.post("/user/statusupdate", {
      userName: userName,
      status: newStatus,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//User profile
export const getUserProfile = async (params: { userName: string }) => {
  try {
    const res = await api.get(`/user/userdetails`, { params });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateUserProfile = async (data: IUserDetail) => {
  try {
    const res = await api.put(`/user/edituser`, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getLocationId = async (location: string) => {
  try {
    const res = await api.post(`/user/location`, { location });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateProfileImage = async (formData: FormData) => {
  try {
    const res = await api.put(`/user/profilereupload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getPaymentUser = async (params: {
  search?: string;
  limit: number;
  page: number;
}) => {
  try {
    const res = await api.get(`/payment/getpayment`, { params });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  try {
    const response = await api.post("/dashboard/changepassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
