import { AxiosError } from "axios";
import api from "./api";

export const userCount = () => {
    return api
      .get("/dashboard/users")
      .then((res) => res)
      .catch((err: AxiosError) => {
        throw err;
      });
  };
  
  export const userGraph = (date: number) => {
    return api
      .get(`/dashboard/usergraph?year=${date}`)
      .then((res) => res)
      .catch((err: AxiosError) => {
        throw err;
      });
  };