import { AxiosError } from "axios";
import api from "./api";

export const getPlan = () => {
  return api
    .get("/plan/getplan")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const updatePlan = (data: { data: string; discountData: string }) => {
  return api
    .post("/plan/changeplan", {
      amount: data.data,
      discount: data.discountData,
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
