import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import favicon from "../../assets/icons/favicon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSearchParams ,Link} from "react-router-dom";
import { resetPassword } from "../../service/signin.api";

const siteUrl = process.env.REACT_APP_MARKETING_URL;
const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  // console.log(searchParams.get("resetCode")); // ▶ URLSearchParams {}  console.log("location", location)
  const initialValue = {
    password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: async (values) => {
      if (searchParams)
        await resetPassword(searchParams.get("resetCode"), {
          newPassword: values.confirmPassword,
        });
    },
  });
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <Box sx={{ padding: "40px 30px" }}>
      {siteUrl ? (
        <Link to={siteUrl} target="blank">
          <img src={favicon} alt="favicon" />
        </Link>
      ) : ('')}
        <Typography
          mt={4}
          sx={{
            textAlign: "left",
            fontFamily: "'Onest', sans-serif",
            fontWeight: "900",
            fontSize: "32px",
          }}
        >
          Reset Password
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            color: "var(--gray-500, #667085)",
          }}
          mb={5}
        >
          Provide your new password here
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Typography
              className="lable-name"
              sx={{ textAlign: "left", mt: "20px", marginBottom: "10px" }}
            >
              New Password
            </Typography>
            <TextField
              fullWidth
              className="formFields"
              sx={{ mt: 0, width: "450px" }}
              name="password"
              placeholder="Enter the new password"
              margin="normal"
              type={showPassword.password ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                      onMouseDown={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      {showPassword.password ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.errors.password && formik.touched.password && (
              <Typography className="formError">
                {formik.errors.password}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              className="lable-name"
              sx={{ textAlign: "left", mt: "20px", marginBottom: "10px" }}
            >
              Confirm Password
            </Typography>
            <TextField
              fullWidth
              className="formFields"
              sx={{ mt: 0, width: "450px" }}
              name="confirmPassword"
              placeholder="Enter the confirm password"
              margin="normal"
              type={showPassword.confirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                      onMouseDown={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    >
                      {showPassword.confirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <Typography className="formError">
                  {formik.errors.confirmPassword}
                </Typography>
              )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPassword;
