import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { inviteUser } from "../../service/User/user.api";
import { toast } from "react-toastify";

interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSendInvite: () => void;
}

const InviteUserDialog: React.FC<InviteUserDialogProps> = ({
  open,
  onClose,
  onSendInvite,
}) => {
  const [email, setEmail] = useState<string>("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleSendInvite = async () => {
    try {
      const response = await inviteUser(email);
      toast.success(response.message.html);
      onClose();
      onSendInvite();
      setEmail("");
    } catch (error: any) {
      toast.error(error.response.data.message.html);
    }
  };
  const handleClose = () => {
    setEmail("");
    onClose();
  };
  return (
    <Dialog open={open} sx={{ fontFamily: '"Onest", sans-serif !important' }}>
      <DialogTitle sx={{ color: "#2CB512" }}>Invite User to AVALBL</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Add user’s email address and they will get an invitation from AVALBL
        </DialogContentText>
        <Typography sx={{ fontFamily: '"Onest", sans-serif !important' }}>
          Email Address
        </Typography>
        <TextField
          placeholder="Enter Email Address"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          sx={{
            textTransform: "none",
            fontFamily: '"Onest", sans-serif !important',
            borderRadius: "10px",
            border: "1px solid #2CB512",
            color: "#2CB512",
            backgroundColor: "#FFF",
            marginRight: "8px",
            width: "220px",
            marginBottom: "20px",
            "&:hover": {
              backgroundColor: "#FFF",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSendInvite}
          sx={{
            textTransform: "none",
            fontFamily: '"Onest", sans-serif !important',
            backgroundColor: "#2CB512",
            borderRadius: "10px",
            color: "#FFF",
            width: "220px",
            marginBottom: "20px",
            "&:hover": {
              backgroundColor: "#2CB512",
            },
          }}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
