import { AxiosError } from "axios";
import api from "./api";

export const getToolList = () => {
  return api
    .get("/tools/gettools")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getSearchToolList = (search: string) => {
  return api
    .get(`/tools/gettools?search=${search}`)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getCategory = () => {
  return api
    .get("/tools/gettoolcategory")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const addTool = (data: { name: string; categoryKey: string }) => {
  return api
    .post("/tools/addtool", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const editTool = (data: {
  name: string;
  categoryKey: string;
  toolId: number;
}) => {
  return api
    .put("/tools/edittool", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const addCategory = (data: { data: string[], isSkill: boolean, isTool: boolean }) => {
  return api
    .post("/skills/addcategory", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const editCategory = (data: { name: string; key: string, isSkill: boolean, isTool: boolean }) => {
  return api
    .put("/skills/editcategory", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const deleteCategory = (data: { categorykey: string, isSkill: boolean }) => {
  return api
    .delete("/skills/deletecategory", {
      data: { categorykey: data.categorykey, isSkill: data.isSkill },
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const deleteRole = (data: { toolId: number }) => {
  return api
    .delete("/tools/deletetool", {
      data: { toolId: data.toolId },
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
