import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE
})

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      console.log("ERROR: Api interceptors", error)
      const token = localStorage.getItem('token');
      originalRequest.headers.Authorization = `Bearer ${token}`;
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry && error.config.url !== "/login/") {
        originalRequest._retry = true;
  
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const response = await api.post('login/refreshtoken', { token: refreshToken });
          const { accessToken } = response.data.data;
  
          localStorage.setItem('token', accessToken);
  
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return api(originalRequest);
        } catch (err) {
          console.log("@@@@@@@@",err)
           window.location.href = '/signin'
        }
      }
  
      return Promise.reject(error);
    }
  );
export default api;