import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import favicon from "../../assets/icons/favicon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../service/signin.api";
import { toast } from "react-toastify";
const SignIn = () => {
  const navigate = useNavigate();
  const initialValue = {
    email: "",
    password: "",
  };
  
  const siteUrl = process.env.REACT_APP_MARKETING_URL;

  
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: async (values) => {
      setOpenBackdrop(true);
      await signIn(values)
        .then((res) => {
          toast.success(res.data.message.html);
          localStorage.setItem("token", res.data.data.accessToken);
          localStorage.setItem("refreshToken", res.data.data.refreshToken);
          navigate("/");
        })
        .catch((error) => {
          console.log("ERROR: SIGNIN", error);
          toast.error(error.response.data.message.html);
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    },
  });
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <CircularProgress sx={{ color: "white" }} />
      </Backdrop>
      <Box sx={{ padding: "64px 30px 64px 30px" }}>
        {siteUrl ? (
        <Link to={siteUrl} target="blank">
          <img src={favicon} alt="favicon" />
        </Link>
      ) : ('')}
        <Typography
          mt={4}
          sx={{
            textAlign: "left",
            fontFamily: "'Onest', sans-serif",
            fontWeight: "900",
            fontSize: "32px",
          }}
        >
          Admin Login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 4 }}>
            <Typography
              className="lable-name"
              sx={{
                marginBottom: "10px",
              }}
            >
              Email <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              className="formFields"
              sx={{ mt: 0, borderRadius: "8px", width: "450px !important" }}
              name="email"
              placeholder="Enter the Email ID"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && formik.touched.email && (
              <Typography className="formError">
                {formik.errors.email}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              className="lable-name"
              sx={{ textAlign: "left", mt: "20px", marginBottom: "10px" }}
            >
              Password{" "}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              className="formFields"
              sx={{ mt: 0, width: "450px" }}
              name="password"
              placeholder="Enter the Password"
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={() => handleMouseDownPassword()}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.errors.password && formik.touched.password && (
              <Typography className="formError">
                {formik.errors.password}
              </Typography>
            )}
          </Box>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            <Link
              to={"/forgot-password"}
              style={{ textDecoration: "none", color: "#2CB512" }}
            >
              Forgot Password?
            </Link>
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Sign In
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SignIn;
