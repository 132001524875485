/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  TablePagination,
  useTheme,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import trashIcon from "../../assets/icons/trashIcon.svg";
import DeleteDialog from "../../components/deletePopUp";
import {
  deleteInviteUser,
  getInviteUser,
  resendInvite,
} from "../../service/User/user.api";
import { IUserInvite } from "../../interface/apiInterface/IUser";
import { toast } from "react-toastify";

type Props = {
  search: string;
  isDialogOpen: boolean;
};
const InviteUserTable = ({ search, isDialogOpen }: Props) => {
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    recordPerPage: 5,
    availableRecords: 0,
  });
  const [isRefreashPage, setIsRefreshPage] = useState(false);
  const theme = useTheme();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteUser, setInviteUser] = useState<IUserInvite | null>(null);
  const [deleteId, setDeleteId] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    //GET INVITE USER LIST
    let param: { search?: string; limit: number; page: number } = {
      limit: pagination.recordPerPage,
      page: pagination.currentPage,
    };
    if (search !== "") {
      param.search = search;
    }
    // setLoading(true);
    getInviteUser(param)
      .then((response) => {
        const data = response.data;

        setPagination({
          ...pagination,
          currentPage: data.users.currentPage,
          totalPage: data.users.totalPage,
          recordPerPage: data.users.usersPerPage,
          availableRecords: data.users.totalAvailableUsers,
        });
        setInviteUser(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [search, isDialogOpen, isRefreashPage]);

  //RESEND EMAIL
  const handleResendLink = async (email: string) => {
    try {
      const response = await resendInvite(email);
      toast.success(response.message.html);
    } catch (error: any) {
      console.error(error);
      toast.error(error.message.html);
    }
  };

  const handleDeleteClick = (userId: number) => {
    setDeleteId(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setInviteUser(inviteUser);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteInviteUser(deleteId);
      toast.success(response.message.html);
      setIsRefreshPage(!isRefreashPage);
      handleCloseDeleteDialog();
    } catch (error: any) {
      console.error(error);
      toast.error(error.message.html);
    }
  };
  const handleChangePage = (_: unknown, newPage: number) => {
    setPagination({ ...pagination, currentPage: newPage + 1 });
    setIsRefreshPage(!isRefreashPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination({ ...pagination, recordPerPage: Number(event.target.value) });
    setIsRefreshPage(!isRefreashPage);
  };
  return (
    <div style={{ padding: "20px" }}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="font-semibold" align="left">
              No
            </TableCell>
            <TableCell className="font-semibold" align="left">
              Email
            </TableCell>
            <TableCell className="font-semibold" align="center">
              Invited Date
            </TableCell>
            <TableCell className="font-semibold" align="center">
              Status
            </TableCell>
            <TableCell className="font-semibold" align="center"></TableCell>
            <TableCell className="font-semibold" align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inviteUser &&
            inviteUser?.users?.results?.map(
              (
                user: {
                  id: number;
                  email: string;
                  inviteDate: string;
                  status: string;
                },
                index: number
              ) => {
                const rowNumber = index + 1;
                return (
                  <TableRow
                    key={user.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {rowNumber}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {user.email}
                    </TableCell>
                    <TableCell align="center">{user.inviteDate}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",

                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor: "rgba(228, 228, 228, 0.89)",
                          pointerEvents: "none", // Disable pointer events
                          cursor: "default",
                          color: "#605F5F",
                        }}
                      >
                        {user.status}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleResendLink(user.email)}
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor: "#E3F5FF",
                          color: "#0B75B4",

                          "&:hover": {
                            backgroundColor: "#E3F5FF",
                          },
                        }}
                      >
                        <ReplayIcon fontSize="small" />
                        Resend Link
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Fab
                        sx={{
                          backgroundColor: "white",
                          boxShadow: "none",
                          border: "1px solid #CCCCCC",
                        }}
                        aria-label="add"
                        size="small"
                        onClick={() => handleDeleteClick(user.id)}
                      >
                        <img src={trashIcon} alt="Trash Icon" />
                      </Fab>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={pagination.availableRecords}
        page={pagination.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.recordPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<span style={{ fontWeight: "bold" }}>Page:</span>}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{
          marginTop: theme.spacing(2),
          "& .MuiTablePagination-caption": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-select": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-toolbar": {
            fontWeight: "bold",
          },
          "& .MuiTablePagination-displayedRows": {
            fontWeight: "bold",
          },
        }}
      />
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDelete}
      />
    </div>
  );
};
export default InviteUserTable;
