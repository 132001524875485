import { Box, Grid, Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserCount from "./userCount";
import "./style.css";
import basicUserIcon from "../../assets/icons/basicUserIcon.svg";
import premiumUserIcon from "../../assets/icons/premiumUserIcon.svg";
import BarChart from "./barChart";
import UserType from "./userType";
import { userCount } from "../../service/dashboard.api";

const Dashboard = () => {
  const [count, setCount] = useState({
    total: 0,
    basic: { total: 0, active: 0, inactive: 0, invited: 0 },
    premium: { total: 0, active: 0, inactive: 0 },
  });
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(true);
  useEffect(() => {
    async function fetch() {
      try {
        const res = await userCount();
        setCount(res.data.data);
      } catch (error) {
        console.log("ERROR: User error", error);
      } finally {
        setOpenBackdrop(false); 
      }
    }
    fetch();
  }, []);

  return (
    <Box p={3} className="">
      <Backdrop open={openBackdrop} sx={{zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress sx={{color:'white'}}/>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item lg={4} sm={4} xs={12} sx={{fontFamily:'Onest-Regular'}}>
          <UserCount
            icon={basicUserIcon}
            title={"Total Users"}
            count={count?.total}
          />
        </Grid>
        <Grid item lg={4} sm={4} xs={12}  sx={{fontFamily:'Onest-Regular'}}> 
          <UserCount
            icon={basicUserIcon}
            title={"Basic Users"}
            count={count.basic.total}
            
          />
        </Grid>
        <Grid item lg={4} sm={4} xs={12} >
          <UserCount
            icon={premiumUserIcon}
            title={"Premium Users"}
            count={count.premium.total}
          />
        </Grid>
        <Grid item lg={9} md={12} xs={12}>
          <BarChart/>
        </Grid>
        <Grid item lg={3} md={12} xs={12}>
          <UserType basic={count.basic} premium={count.premium}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
