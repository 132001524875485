import React, { useEffect, useState } from "react";
import "./style.css";
import "./responsive.css";
import { Button, Container, Grid, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import BannerImage from "../../assets/images/BannerImage.png";
import profileImage from "../../assets/images/profileImage.png";
import sliderImage1 from "../../assets/images/sliderImage1.png";
import sliderImage2 from "../../assets/images/sliderImage2.png";
import sliderImage3 from "../../assets/images/sliderImage3.png";
import maiIcon from "../../assets/images/mailIcon.png";
import mapPin from "../../assets/images/mapPin.png";
import { getUserProfile } from "../../service/User/user.api";
import { IUserProfile } from "../../interface/apiInterface/IUser";
import Map from "./map";
import Carousel from "./carousel";

const defaultImages = [sliderImage1, sliderImage2, sliderImage3];

const UserProfile = () => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const [data, setData] = useState<IUserProfile | null>(null);
  useEffect(() => {
    const check = window.location.href.includes("profile");
    const id = parseInt(window.location.href.split("/")[4], 10);

    if (check) {
      getUserProfileInfo(id);
    }
  }, []);

  const getUserProfileInfo = async (id: number) => {
    try {
      const response = await getUserProfile({ userName: "id" });

      setData(response.data);
    } catch (error: any) {
      // toast.error(error.message.html);
    }
  };

  const getAbsoluteURL = (path: string) => {
    const externalLink = path; // Replace this with your dynamic link

    const hrefValue = externalLink.startsWith("https")
      ? externalLink
      : `http://${externalLink}`;

    return hrefValue;
  };

  return (
    <>
      <Grid sx={{ backgroundColor: "#eaecf0" }}>
        {data && (
          <section className="acc-banner">
            <img
              src={data.headerPath === null ? BannerImage : data.headerPath}
              alt="img"
              height="184px !important"
              width="100% !important"
              onError={(e: any) => {
                e.target.src = { BannerImage };
              }}
            />
          </section>
        )}
        <div className="acc-header prem-view">
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7} sm={6} sx={{ width: "1756px" }}>
                <div className="acc-cont">
                  {data && (
                    <div className="acc-img">
                      <img
                        src={
                          data.profilePath === null
                            ? profileImage
                            : data.profilePath
                        }
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  )}
                  <div className="det-info">
                    <div className="acc-name dis-align">
                      <Typography variant="h3">
                        {data && `${data.firstName} ${data.lastName}`}
                      </Typography>
                      <Typography variant="body1" className="until-date">
                        {data &&
                          data.availability.label !== "-" &&
                          data.availability.label}
                      </Typography>
                    </div>
                    <div className="skill-info dis-align">
                      <div className="date dis-align">
                        <img src={maiIcon} alt="img" className="img-fluid" />
                        <Typography variant="body1">
                          {data && data.jobTitle}
                        </Typography>
                      </div>
                      <Typography variant="body1">
                        Username <span>{data && data.userName}</span>
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={5} sm={6} sx={{ mt: 2 }}>
                <div className="list-btn right-btn">
                  <Button
                    sx={{ width: "160px" }}
                    className="common-button-green"
                    variant="contained"
                    color="primary"
                    data-toggle="modal"
                    data-target="#exampleModal-hirenow"
                  >
                    Hire Now
                  </Button>
                  <Button
                    sx={{ width: "160px" }}
                    className="common-button-white"
                    variant="outlined"
                  >
                    Message
                  </Button>
                  <Button
                    sx={{ width: "160px", marginLeft: 2 }}
                    className="common-button-white"
                    variant="outlined"
                  >
                    Add to My Roster
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        {data && (
          <Grid>
            <Carousel
              images={
                data.porfolio.length === 0 ? defaultImages : data.porfolio
              }
            />
          </Grid>
        )}

        <section className="acc-info">
          <Container sx={{ fontFamily: '"Onest", sans-serif !important;' }}>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <div className="acc-box">
                  <Typography variant="h4" className="title-name">
                    About {data && `${data.firstName} ${data.lastName}`}
                  </Typography>
                  <Typography variant="body1" className="acc-text">
                    {data && data.about}
                  </Typography>
                  <div className="rate-box acc-skill">
                    <div className="aval-title">
                      <h4 className="title-name">Availability</h4>
                      <p className="acc-text">
                        {data && data.availability.label}
                      </p>
                    </div>
                    <div className="aval-title rate-rabge-box">
                      <h4 className="title-name">Rate Range</h4>
                      <p className="acc-text"> {data && data.range.label}</p>
                    </div>
                  </div>
                  <div className="rate-box acc-skill">
                    <div className="aval-title">
                      <h4 className="title-name">Job Title</h4>
                      <p className="acc-text">{data && data.jobTitle} </p>
                    </div>
                    <Grid className="aval-title rate-rabge-box">
                      <h4 className="title-name">Total-Experience</h4>
                      {data && data.experience && (
                        <>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#0000005c",
                              display: "flex",
                            }}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={Math.min(100, data.experience * 20)}
                              style={{ width: "100px", height: "12px" }}
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={Math.min(100, (data.experience - 5) * 25)}
                              style={{ width: "100px", height: "12px" }}
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                data.experience > 10
                                  ? 100
                                  : Math.min(100, (data.experience - 10) * 25)
                              }
                              style={{ width: "100px", height: "12px" }}
                            />
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#0000005c",
                              display: "flex",
                            }}
                          >
                            <Grid
                              style={{ width: "100px", textAlign: "center" }}
                            >
                              1-5
                            </Grid>
                            <Grid
                              style={{ width: "100px", textAlign: "center" }}
                            >
                              5-10
                            </Grid>
                            <Grid
                              style={{ width: "100px", textAlign: "center" }}
                            >
                              10+
                            </Grid>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </div>
                  <div className="client-list list-box acc-skill">
                    <h4 className="title-name">Client List</h4>
                    <ul style={{ paddingLeft: 0 }}>
                      {(data && data.company.length === 0) ||
                      data?.company === null ? (
                        <li>Not Available</li>
                      ) : (
                        data?.company.map((company, index) => (
                          <li key={index}>{company}</li>
                        ))
                      )}
                    </ul>
                  </div>
                  <div className="skill-sec list-box acc-skill">
                    <h4 className="title-name">Skill</h4>
                    <ul style={{ paddingLeft: 0 }}>
                      {(data && data.skills.length === 0) ||
                      data?.skills === null ? (
                        <li style={{ color: "#73787e" }}>Not Available</li>
                      ) : (
                        data?.skills.map((category, index) => (
                          <li key={index}>
                            <div className="check-box">
                              <div className="check-head">
                                <h4>{category.category.label}</h4>
                              </div>
                              <ul>
                                {category.skills.map((skill, skillIndex) => (
                                  <li key={skillIndex}>{skill.label}</li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                  <div className="Tools-sec list-box ">
                    <h4 className="title-name">Tools</h4>
                    <ul style={{ paddingLeft: 0 }}>
                      {(data && data.tools.length === 0) ||
                      data?.tools === null ? (
                        <li style={{ color: "#73787e" }}>Not Available</li>
                      ) : (
                        data?.tools.map((category, index) => (
                          <li key={index}>
                            <div className="check-box">
                              <div className="check-head">
                                <h4>{category.category.label}</h4>
                              </div>
                              <ul>
                                {category.tools.map((tool, toolIndex) => (
                                  <li key={toolIndex}>{tool.label}</li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="acc-box right-box">
                  <div className="lang-list acc-skill">
                    <h4 className="title-name">Language</h4>
                    <ul style={{ paddingLeft: 0 }}>
                      {(data && data.language.length === 0) ||
                      data?.language === null ? (
                        <li style={{ color: "#73787e" }}>Not Available</li>
                      ) : (
                        data?.language.map((language, index) => (
                          <li key={index}>{language}</li>
                        ))
                      )}
                    </ul>
                  </div>
                  <div className="loca-cont acc-skill">
                    <h4 className="title-name">Location</h4>
                    <Grid sx={{ pt: "20px", width: "850px" }}>
                      {data && data.location && (
                        <Map
                          center={{
                            lat: data?.location.lat,
                            lng: data?.location.long,
                          }}
                          zoom={10}
                        />
                      )}
                    </Grid>
                    <div className="map-cont">
                      <div className="date">
                        <img src={mapPin} className="img-fluid" alt="img" />
                        <p>{data && data.location.label}</p>
                      </div>
                    </div>
                  </div>
                  <div className="port-cont acc-skill">
                    <h4 className="title-name">Portfolio Link</h4>
                    {data && data.portfolioLink && (
                      <p className="acc-text">
                        <a
                          href={getAbsoluteURL(data.portfolioLink)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.portfolioLink}
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="media-cont acc-skill">
                    <h4 className="title-name">Social Media</h4>
                    <ul style={{ paddingLeft: 0 }}>
                      {(data && data.socialMedia.length === 0) ||
                      data?.socialMedia === null ? (
                        <li style={{ color: "#73787e" }}>Not Available</li>
                      ) : (
                        data?.socialMedia.map((social, index) => (
                          <li key={index}>
                            <a
                              href={getAbsoluteURL(social.link)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={social.logoPath}
                                alt={social.label}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Grid>
    </>
  );
};

export default UserProfile;
