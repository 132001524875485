import { AxiosError } from "axios";
import api from "./api";

export const getSkillList = () => {
  return api
    .get("/skills/getskill")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getSearchSkillList = (search: string) => {
  return api
    .get(`/skills/getskill?search=${search}`)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getCategory = () => {
  return api
    .get("/skills/getskillcategory")
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const addSkill = (data: {
  name: string;
  categoryKey: string;
  description: string;
}) => {
  return api
    .post("/skills/addskill", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const editSkill = (data: {
  name: string;
  categoryKey: string;
  description: string;
  skillId: number;
}) => {
  return api
    .put("/skills/editskill", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const addCategory = (data: { data: string[], isSkill: boolean, isTool: boolean }) => {
  return api
    .post("/skills/addcategory", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const editCategory = (data: { name: string; key: string, isSkill: boolean, isTool: boolean }) => {
  return api
    .put("/skills/editcategory", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const deleteCategory = (data: { categorykey: string, isSkill: boolean }) => {
  return api
    .delete("/skills/deletecategory", {
      data: { categorykey: data.categorykey, isSkill: data.isSkill },
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const deleteRole = (data: { skillId: number }) => {
  return api
    .delete("/skills/deleteskill", {
      data: { skillId: data.skillId },
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
