/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

type Props = {
  open: boolean;
  handleClose: () => void;
  addTodo: (category: {
    name: string;
    categoryKey: string;
    description: string;
  }) => Promise<void>;
  categoryList: {
    label: string;
    key: string;
    description: string;
  }[];
  category: {
    name: string;
    categoryKey: string;
    description: string;
    // categoryLabel :string
  };
  isAddRole: boolean;
  dynamicValues: {
    categoryKey: string;
  };
};
const RoleDialog = ({
  open,
  handleClose,
  addTodo,
  categoryList,
  category,
  isAddRole,
  dynamicValues,
}: Props) => {
    const validationSchema = Yup.object().shape({
    name: Yup.string().required("Role name is required"),
    categoryKey: Yup.string().required("Category is required"),
    description: Yup.string().required("Description is required"),
  });

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: category,
    validationSchema,
    onSubmit: async (values) => {
      addTodo(values);
    },
  });

  useEffect(() => {
   

    const fetchData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      formik.setValues({
        ...category,
        categoryKey: dynamicValues.categoryKey,
      });

      setLoading(false);
    };
    if(isAddRole){
      fetchData();
    }
    
  }, [dynamicValues.categoryKey, category]);

  const getSelectedLabel = (selectedKey: string) => {
   
    
    const selectedItem = categoryList.find(item => item.key === selectedKey);
  
    return selectedItem ? selectedItem.label : "Category name";
  };

  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading && (
        <>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ padding: "18px 24px 5px 24px" }}
          >
            <Typography
              sx={{
                fontFamily: "Onest-Semibold",
                fontWeight: 600,
                fontSize: "18px",
                color: "#2CB512",
              }}
            >
              {isAddRole ? "Add" : "Edit"} Sub Skill
            </Typography>
          </DialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              {/* <Typography
                sx={{
                  fontFamily: "Onest-Medium",
                  fontSize: "16px",
                  color: "var(--gray-500, #667085)",
                }}
              >
                {isAddRole ? "Add" : "Edit"} the roles to protocolar category
              </Typography> */}
              <Box>
                <Typography
                  className="lable-name"
                  mt={2}
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Main Skill 
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </Typography>

                <Select
                  fullWidth
                  displayEmpty
                  sx={{ borderRadius: "10px" }}
                  name="categoryKey"
                  value={formik.values.categoryKey}
                  onChange={formik.handleChange}
                  renderValue={(selected) => getSelectedLabel(selected)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    Main Skills{" "}
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </MenuItem>
                  {categoryList.map((name) => (
                    <MenuItem key={name.key} value={name.key}>
                      {name.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.categoryKey && formik.touched.categoryKey && (
                  <Typography className="formError">
                    {formik.errors.categoryKey}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography
                  className="lable-name"
                  mt={2}
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Sub Skill{" "}
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  className="formFields"
                  placeholder="Sub Skill"
                  sx={{ mt: 0, borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  name="name"
                  value={formik.values.name}
                />
              </Box>
              {formik.errors.name && formik.touched.name && (
                <Typography className="formError">
                  {formik.errors.name}
                </Typography>
              )}
              <Box>
                <Typography
                  className="lable-name"
                  mt={2}
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Description
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  className="formFields"
                  placeholder="Description"
                  sx={{ mt: 0, borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  name="description"
                  value={formik.values.description}
                />
              </Box>
              {formik.errors.description && formik.touched.description && (
                <Typography className="formError">
                  {formik.errors.description}
                </Typography>
              )}
            </DialogContent>
            <DialogActions id="alert-dialog-description" sx={{ px: 7, pb: 3 }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  fontFamily: "Onest-Semibold",
                  textTransform: "none",
                  width: "100%",
                  fontSize: "14px",
                  borderColor: "#2CB512",
                  color: "#2CB512",
                  borderRadius: "12px",
                  "&:hover": {
                    color: "#2CB512",
                    borderColor: "#2CB512",
                    backgroundColor: "white",
                  },
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  fontFamily: "Onest-Semibold",
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: "#2CB512",
                  fontSize: "14px",
                  borderRadius: "12px",
                  "&:hover": { backgroundColor: "#2CB512" },
                }}
              >
                {isAddRole ? "Add" : "Update"} Sub Skill
              </Button>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
};

export default RoleDialog;
