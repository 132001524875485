import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDiscountPlan, promoCodePlan } from "../../service/discount.api";
import { toast } from "react-toastify";
import "./style.css";

const Discount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);
  const [getPromoValid, setGetPromoValid] = useState([]);
  const [optionvalue, setOptionValue] = useState("");
  const [error, setError] = useState({
    email: "",
    validEmail: "",
  });
  const [creatDiscount, setCreateDiscount] = useState<any>({
    promoId: 0,
    email: [],
  });

  const handleInputChange = (event: any) => {
    setEnteredEmail(
      event.target.value
        .replace(/^\s+/, "")
        .replace(/\s+/g, " ")
        .replace(/^[^a-zA-Z@.]+/, "")
    );
    setError((preError: any) => ({
      ...preError,
      validEmail: "",
      email: "",
    }));
  };

  const isValidEmail = (email: any) => {
    const emails = email.split(/[,\s]+/);

    const isValid = emails.every((part: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(part);
    });
    return isValid;
  };

  const handleInviteClick = () => {
    if (enteredEmail === "") {
      setError((preError) => ({
        ...preError,
        validEmail: "Enter a valid email address",
      }));
      return;
    }
    const enteredEmailsArray = enteredEmail.split(/[,\s]+/).filter(Boolean);

    if (enteredEmailsArray.length > 1) {
      setError((preError) => ({
        ...preError,
        validEmail: "Enter only one email address at a time",
      }));
      return;
    }

    const email = enteredEmailsArray[0];
    if (!isValidEmail(email)) {
      setError((preError) => ({
        ...preError,
        validEmail: "Enter a valid email address",
      }));
      return;
    }

    if (invitedEmails.includes(email)) {
      setError((preError) => ({
        ...preError,
        validEmail: "Email is already added",
      }));
      return;
    }

    const prevEmails = [...invitedEmails];

    setInvitedEmails((prevEmails) => [...prevEmails, email]);
    setCreateDiscount((prevData: any) => ({
      ...prevData,
      email: [...prevEmails, email],
    }));
    setEnteredEmail("");
    setError((preError) => ({
      ...preError,
      validEmail: "",
    }));
  };

  useEffect(() => {
    async function fetch() {
      try {
        const res = await getDiscountPlan();
        setGetPromoValid(res.data.data.validity);
        setLoading(false);
      } catch (error) {
        console.log("ERROR: Plan list", error);
      }
    }
    fetch();
  }, []);

  const handleChange = (event: any) => {
    setOptionValue(event.target.value);

    setCreateDiscount((prevData: any) => ({
      ...prevData,
      promoId: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (!optionvalue) {
      toast.error("Please select number of days");
      return;
    }

    if (invitedEmails.length === 0) {
      toast.error("Please add at least one email address");
      return;
    }
    setLoading(true);
    try {
      const res = await promoCodePlan(creatDiscount);
      toast.success(res.data.message.html);
      setCreateDiscount({ promoId: 0, email: [] });
      setOptionValue("");
      setInvitedEmails([]);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.data.message.html);
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        sx={{
          backgroundColor: "#fff",
          padding: "32px 28px 32px 28px",
          maxWidth: "964px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Onest-Semibold",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
            paragraph: "18px",
            color: "#2CB512",
          }}
        >
          Share discount code to users
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Onest', sans-serif",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            paragraph: "16px",
            color: "#73787E",
            mt: "12px",
          }}
        >
          Select number of users you want to share discount code
        </Typography>

        <Typography
          className="lable-name"
          sx={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            alignSelf: "stretch",
            marginBottom: "10px",
            mt: "25px",
          }}
        >
          Select number of days
        </Typography>
        <Select
          className="formFields"
          sx={{
            mt: 0,
            borderRadius: "8px",
            width: "100% !important",
            fontFamily: "'Onest', sans-serif",
            height: "47px",
          }}
          value={optionvalue}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Select number of days" }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: "8px",
                border: "1px solid grey",
              },
            },
          }}
        >
          {getPromoValid.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.type}
            </MenuItem>
          ))}
        </Select>

        <Typography
          sx={{
            fontFamily: "Onest-Semibold",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
            paragraph: "18px",
            color: "#2CB512",
            mt: "20px",
          }}
        >
          Add users
        </Typography>
        <Grid>
          <TextField
            className="formFields uniquetextBox"
            sx={{
              mt: 2,
              width: {
                xs: "100% !important",
                sm: "100% !important",
                md: "100% !important",
                lg: "100% !important",
                xl: "100% !important",
              },
            }}
            type="email"
            name="email"
            placeholder="Enter email address"
            value={enteredEmail}
            onChange={handleInputChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    className="common-button-green"
                    sx={{
                      width: "80px !important",
                      height: "40px !important",
                    }}
                    onClick={handleInviteClick}
                  >
                    Add
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {error.email && <div style={{ color: "red" }}>{error.email}</div>}
          {error.email === ""
            ? error.validEmail && (
                <div style={{ color: "red" }}>{error.validEmail}</div>
              )
            : ""}
          <Grid>
            {invitedEmails.map((email) => (
              <Chip
                key={email}
                label={email}
                sx={{
                  margin: "4px",
                  mt: 2,
                  fontFamily: "'Onest', sans-serif",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  paragraph: "16px",
                }}
                onDelete={() => {
                  setInvitedEmails((prevEmails) =>
                    prevEmails.filter((e) => e !== email)
                  );
                }}
              />
            ))}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          className="common-button-green"
          sx={{
            // width: "80px !important",
            mt: "15px",
            height: "40px !important",
          }}
          onClick={handleSubmit}
        >
          Share Discount Code
        </Button>
      </Grid>
    </div>
  );
};

export default Discount;
