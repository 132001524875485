import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Paper, TableContainer, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";
import ActiveUserTable from "./activeUserTable";
import InviteUserTable from "./inviteUserTable";
import InviteUserDialog from "./inviteUserDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography style={{ fontWeight: "bold" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const User = () => {
  const [value, setValue] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearch("");
    setSearchParam("");
    setValue(newValue);
  };

  const handleInviteUserClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSendInvite = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    setSearchParam(search);
  }, [search]);

  return (
    // <Card >
    <Box p={3}>
      <Box sx={{ borderColor: "inherit", fontWeight: "Bold" }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab
            label="Users"
            {...a11yProps(0)}
            sx={{
              backgroundColor: value === 0 ? "white" : "inherit",
              fontWeight: "bold",
            }}
          />
          <Tab
            label="Invited Users"
            {...a11yProps(1)}
            sx={{
              backgroundColor: value === 1 ? "white" : "inherit",
              fontWeight: "bold",
            }}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px",
          backgroundColor: "#FFF",
        }}
      >
        <TextField
          id="search"
          placeholder="Search username or email"
          className="formFields placeholder-black"
          variant="outlined"
          sx={{
            width: "90%",
            "& input::placeholder": {
              color: "black !important", // Specify the color for the placeholder text
            },
          }}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            // Fetch data when search field is changed
            if (event.target.value.trim() === "") {
              setSearchParam(""); // Reset the search parameter to fetch all data
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchParam(search);
            }
          }}
          InputProps={{
            endAdornment: (
              <Box
                sx={{
                  marginRight: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={() => setSearchParam(search)}>
                  <SearchIcon sx={{ color: "#646972" }} />
                </IconButton>
              </Box>
            ),
          }}
        />

        <Button
          className="common-button-green comBtngreen"
          type="submit"
          variant="contained"
          onClick={handleInviteUserClick}
          sx={{ width: "120px", marginLeft: "20px" }}
        >
          Invite User
        </Button>
      </Box>
      <InviteUserDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSendInvite={handleSendInvite}
      />
      <CustomTabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <ActiveUserTable search={searchParam} />
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <InviteUserTable search={searchParam} isDialogOpen={isDialogOpen} />
        </TableContainer>
      </CustomTabPanel>
    </Box>
  );
};
export default User;
