import React, { useEffect } from "react";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel";
import "./style.css";

type Props = {
  images: string[];
};

const Carousel = ({ images }: Props) => {
  const duplicatedImages = Array.from(
    { length: Math.max(12, images.length) },
    (_, index) => images[index % images.length]
  );

  useEffect(() => {
    const initializeSlider = () => {
      $(".gallery-img").slick({
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: true,
        customPaging: function (slider: any, i: any) {
          if (i < 3) {
            return '<button class="dot"></button>';
          }
          return "";
        },
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };

    initializeSlider();

    return () => {
      $(".gallery-img").slick("unslick");
    };
  }, []);

  return (
    <section className="gallery-slider">
      <div className="gallery-img">
        {duplicatedImages.map((image, index) => (
          <div className="slick-slide" key={index}>
            <div className="img-container">
              <img src={image} alt={`img-${index}`} className="img-fluid" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Carousel;
